export const DATA = [
    {
        name: 'Fırın Serisi',
        url: 'firin-serisi',
        icon: '/images/menu/5.svg',

        products: [
            {
                title_1: 'FIRIN SERİSİ',
                title_2: 'Francalalık Buğday Unu',
                url: 'francalik-bugday-unu',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'En yeni ürünlerimizden biri olan Fırın Serisi altında yer alan Francalalık unumuz özel paçallardan üretilir. Yüksek protein içeriğine sahiptir. Yüksek randımanlı, performanslı, kaliteli tezgah ekmekleri için tercih edilir. Çuvaldan aldığınız ekmek adedinizi arttırır.',
                image: '/images/products/ekmeklik.png',
                image_main: '/images/parallax/ekmeklik.png',
                teslimSekilleri: ['1', '4', '5'],
                kullanimAlanlari: 'Taşfırın, Karafırın, Döner ve Tünel Fırın Ekmekleri',
                urunGrubu: 'Endüstriyel & Artizan Ekme',
                pdf: '',
                parallaxText1: 'Yüksek Randıman için',
                parallaxText2: 'Aklın Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 12,
                    kül: 0.65,
                    energy: 250,
                    gluten: 28,
                    sed: 45,
                    stab: null,
                },
            },

            {
                title_1: 'FIRIN SERİSİ',
                title_2: 'Turuncu Ekmeklik',
                url: 'turuncu-ekmeklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'İlk çıktığı günden beri İnceoğlu Turuncusu ustaların teveccüh gösterdiği yüksek kaliteli taşfırın ekmeklik unumuzdur. Performansı yüksek, gözenek yapısı homojen, nar gibi kızarmış, güzel bir şekilde kabarmış çıtır çıtır ekmekleriniz için yıllardır aynı özen ve titizlikle üretiyoruz.',
                image: '/images/products/8.png',
                image_main: '/images/parallax/8.png',
                teslimSekilleri: ['1', '4', '5'],
                kullanimAlanlari: 'Taşfırın, Karafırın, Döner Fırın Ekmeği Tava Ekmeği, Vakfıkebir Ekmeği',
                urunGrubu: 'Endüstriyel Ekmek',
                pdf: '',
                parallaxText1: 'Her seferinde aynı sonuç için​',
                parallaxText2: 'Fırıncının Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 12,
                    kül: 0.65,
                    energy: 250,
                    gluten: 28,
                    sed: 45,
                    stab: null,
                },
            },

            {
                title_1: 'FIRIN SERİSİ',
                title_2: 'Yeşil Ekmeklik',
                url: 'yesil-ekmeklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Yıllardır fırın ustalalarımızın kullandığı İnceoğlu Yeşili döner, tünel, karafırın ve borulu fırınlarda tercih edilmektedir. Yüksek performanslı, çıtır çıtır, kabaran ekmekler için yıllardır aynı özen ve titizlikle üretiyoruz.',
                image: '/images/products/10.png',
                image_main: '/images/parallax/10.png',
                teslimSekilleri: ['1', '4', '5'],
                kullanimAlanlari: 'Karafırın, Taşfırın, Döner, Tünel Fırın Ekmeği Tava Ekmeği, Vakfıkebir Ekmeği',
                urunGrubu: 'Endüstriyel Ekmek',
                pdf: '',
                parallaxText1: 'Mis gibi bir tava ekmeği için',
                parallaxText2: 'Uzmanların Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 11.5,
                    kül: 0.65,
                    energy: 245,
                    gluten: 27,
                    sed: 45,
                    stab: null,
                },
            },

            {
                title_1: 'Fırın Serisi',
                title_2: 'Kırmızı Ekmeklik',
                url: 'kirmizi-ekmeklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'İnceoğlu Kırmızısı döner, tünel, karafırın ve borulu fırınlarda tercih edilmekle beraber özellikle karafırınlarda nam salmıştır. Yüksek performanslı,nar gibi kızaran, puf puf kabaran ekmekler için yıllardır aynı özen ve titizlikle üretiyoruz.',
                image: '/images/products/3.png',
                image_main: '/images/parallax/3.png',
                teslimSekilleri: ['1', '4', '5'],
                kullanimAlanlari: 'Karafırın, Taşfırın, Döner, Tünel Fırın Ekmeği Tava Ekmeği, Vakfıkebir Ekmeği',
                urunGrubu: 'Endüstriyel Ekmek',
                pdf: '',
                parallaxText1: 'Nar gibi ekmekler için​',
                parallaxText2: 'Kürekçinin Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 11,
                    kül: 0.65,
                    energy: 240,
                    gluten: 27,
                    sed: 45,
                    stab: null,
                },
            },

            {
                title_1: 'FIRIN SERİSİ',
                title_2: 'Sarı Ekmeklik',
                url: 'sari-ekmeklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Fırın ustalalarımızın tercihi İnceoğlu Sarısı taşfırın ve karafırın ekmeklik unlarımızın yanında paçalda kullanılabilir veya kazan süresinin daha düşük tutulması istendiğinde reçeteye katılabilir. Çıtır çıtır, kabarık ekmekler için yıllardır aynı özen ve titizlikle üretiyoruz.',
                image: '/images/products/5.png',
                image_main: '/images/parallax/5.png',
                teslimSekilleri: ['1', '4', '5'],
                kullanimAlanlari: 'Karafırın, Taşfırın, Döner, Tünel Fırın Ekmeği Tava Ekmeği, Vakfıkebir Ekmeği',
                urunGrubu: 'Endüstriyel Ekmek',
                pdf: '',
                parallaxText1: 'Kazanda minimum süre için',
                parallaxText2: 'Hamurkarın Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 10.5,
                    kül: 0.65,
                    energy: 230,
                    gluten: 26,
                    sed: 40,
                    stab: null,
                },
            },

            {
                title_1: 'FIRIN SERİSİ',
                title_2: 'Pidelik',
                url: 'pidelik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Ramazan ayı gelmeden fırınlar İnceoğlu Mavi’sini çoktan sipariş vermişlerdir bile çünkü yıllardır ustalarımızın vazgeçilmezi olmuştur. Uzun bir günün ardından sevdiklerimizle aynı sofrada buluşmadan o mis kokulu, çıtır çıtır pideyi almak keyifli bir ritüel haline gelmiştir.',
                image: '/images/products/2.png',
                image_main: '/images/parallax/2.png',
                teslimSekilleri: ['1', '4', '5'],
                kullanimAlanlari: 'Ramazan Pidesi, Malzemeli Pide',
                urunGrubu: 'Pide Çeşitleri',
                pdf: '',
                parallaxText1: 'Sıcacık Ramazan Pideleri için',
                parallaxText2: 'Ustaların Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 11,
                    kül: 0.60,
                    energy: 250,
                    gluten: 27,
                    sed: 45,
                    stab: null,
                },
            },
        ],
    },

    {
        name: 'Pastane Serisi',
        url: 'pastane-serisi',
        icon: '/images/menu/1.svg',

        products: [
 

            {
                title_1: 'PASTANE SERİSİ',
                title_2: 'Pamukkale Pastalık',
                url: 'pastalik-boreklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Pastane ve ev tipi pasta börek yapımına uygun Pastalık Böreklik unumuz Pamukkale Serisi altında yer almaktadır. Her türlü pasta çeşidi için uygun, güzel kabaran, çökme yapmayan, protein yapısı kuvvetli bir undur.',
                image: '/images/products/11.png',
                image_main: '/images/parallax/11.png',
                teslimSekilleri: ['2', '3', '4', '5'],
                kullanimAlanlari: 'Yaş Pasta, Kuru Pasta, Kek, Tart, Cheesecake Poğaça, Kurabiye, Brownie',
                urunGrubu: 'Pasta & Börek',
                pdf: '',
                parallaxText1: 'Pasta Börek ve Mayalılarda​',
                parallaxText2: 'Pastanemin Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },



            {
                title_1: 'PASTANE SERİSİ',
                title_2: 'Pamukkale Unlu Mamüller',
                url: 'unlu-mamuller',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Her türlü unlu mamüller yapımı için uygun Pamukkale Serisi altında yer alan joker buğday unudur. Pasta, kek ve kurabiye yapımına uygun, protein yapısı dengeli bir undur.',
                image: '/images/products/15.png',
                image_main: '/images/parallax/15.png',
                teslimSekilleri: ['3', '4', '5'],
                kullanimAlanlari: 'Kuru Pasta, Kek, Poğaça, Kurabiye, Brownie',
                urunGrubu: 'Unlu Mamüller',
                pdf: '',
                parallaxText1: 'Joker niyetine​',
                parallaxText2: 'Pasta Şefinin Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 10,
                    kül: 0.65,
                    energy: 230,
                    gluten: 26,
                    sed: 40,
                    stab: null,
                },
            },
        ],
    },

    {
        name: 'Usta Serisi',
        url: 'usta-serisi',
        icon: '/images/menu/3.svg',
        image_main: '/images/parallax/2.png', // /images/products/2.png => /images/parallax/2.png
        images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],

        products: [

            {
                title_1: 'USTA SERİSİ',
                title_2: 'Pidelik',
                url: 'pidelik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Her yöre için farklı anlamlar taşır. Kimisi için simittir, kimisi için gevrek. İnceoğlu Özel Amaçlı Simitlik Un isim ayırmaz. Dilerseniz simit, dilerseniz gevrekleriniz için kullanabilirsiniz. Sıcak şerbet uygulamalarına dirençli, soğuk şerbet prosesi için uygun, lastiklenme yapmayan bir undur.İnceoğlu Simitlik unundan yapılan simitler çıtır çıtır olur.',
                image: '/images/products/pidelik.png',
                image_main: '/images/parallax/pm.png',
                teslimSekilleri: ['1', '2', '3', '4', '5'],
                kullanimAlanlari: 'Simit & Gevrek, Bagel',
                urunGrubu: 'Simit',
                pdf: '',
                parallaxText1: 'Çıtır çıtır Simitler için Gevrekler​​',
                parallaxText2: 'Simitçimin Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },





            {
                title_1: 'USTA SERİSİ',
                title_2: 'Bisküvilik',
                url: 'pidelik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Her yöre için farklı anlamlar taşır. Kimisi için simittir, kimisi için gevrek. İnceoğlu Özel Amaçlı Simitlik Un isim ayırmaz. Dilerseniz simit, dilerseniz gevrekleriniz için kullanabilirsiniz. Sıcak şerbet uygulamalarına dirençli, soğuk şerbet prosesi için uygun, lastiklenme yapmayan bir undur.İnceoğlu Simitlik unundan yapılan simitler çıtır çıtır olur.',
                image: '/images/products/biskuvilik.png',
                image_main: '/images/parallax/pm.png',
                teslimSekilleri: ['1', '2', '3', '4', '5'],
                kullanimAlanlari: 'Simit & Gevrek, Bagel',
                urunGrubu: 'Simit',
                pdf: '',
                parallaxText1: 'Çıtır çıtır Simitler için Gevrekler​​',
                parallaxText2: 'Simitçimin Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },



            {
                title_1: 'USTA SERİSİ',
                title_2: 'Simitlik',
                url: 'simitlik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Her yöre için farklı anlamlar taşır. Kimisi için simittir, kimisi için gevrek. İnceoğlu Özel Amaçlı Simitlik Un isim ayırmaz. Dilerseniz simit, dilerseniz gevrekleriniz için kullanabilirsiniz. Sıcak şerbet uygulamalarına dirençli, soğuk şerbet prosesi için uygun, lastiklenme yapmayan bir undur.İnceoğlu Simitlik unundan yapılan simitler çıtır çıtır olur.',
                image: '/images/products/pm.png',
                image_main: '/images/parallax/pm.png',
                teslimSekilleri: ['1', '2', '3', '4', '5'],
                kullanimAlanlari: 'Simit & Gevrek, Bagel',
                urunGrubu: 'Simit',
                pdf: '',
                parallaxText1: 'Çıtır çıtır Simitler için Gevrekler​​',
                parallaxText2: 'Simitçimin Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },

            {
                title_1: 'USTA SERİSİ',
                title_2: 'Hamburgerlik',
                url: 'hamburgerlik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Endüstriyel hamburger üretimi için yüksek performans ve randımanlı İnceoğlu Özel Amaçlı Hamburgerlik Un idealdir. Sandviç ve tost ekmekleriniz için de aynı un kullanılabilir.',
                image: '/images/products/hm.png',
                image_main: '/images/parallax/hm.png',
                teslimSekilleri: ['1', '2', '3', '4', '5'],
                kullanimAlanlari: 'Hamburger, Sandviç, Tost Ekmekleri',
                urunGrubu: 'Hamburger Ekmeği',
                pdf: '',
                parallaxText1: 'Pofuduk Brioche Ekmekler için​',
                parallaxText2: 'Şeflerin Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },
        ],
    },

    {
        name: 'Yöresel Seri',
        url: 'yoresel-serisi',
        icon: '/images/menu/2.svg',
        image_main: '/images/parallax/1.png', // /images/products/1.png => /images/parallax/1.png
        images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],

        products: [
            {
                title_1: 'YÖRESEL SERİ',
                title_2: 'Yufka Ekmeklik',
                url: 'yufka-ekmeklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Yazın başlangıcı kışın habericisi olmuştur. Hanımlar toplanmış; kimisi elini hamura bulamış, kimisi oklavayı eline almış, kimisi de sac tavanın başında oturmuştur. Tüm aile için bütün kış boyunca afiyetle yenecek taptaze yufka ekmekler pişmeye başlamıştır bile. İnceoğlu Özel Amaçlı Yufka Ekmeklik Un, kolay açılır, az çekme yapar ve güzel renk alır.',
                image: '/images/products/1.png',
                image_main: '/images/parallax/1.png',
                teslimSekilleri: ['2'],
                kullanimAlanlari: 'Köy Yufkası, Yufka Ekmek',
                urunGrubu: 'Yufka Ekmeği',
                pdf: '',
                parallaxText1: 'Köy Yufkalarında',
                parallaxText2: 'Dezemin Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 10,
                    kül: 1.2,
                    energy: 220,
                    gluten: 28,
                    sed: 40,
                    stab: null,
                },
            },

            {
                title_1: 'YÖRESEL SERİ',
                title_2: 'Tam Buğday Unu',
                url: 'tam-bugday-unu',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Özel reçetelendirdiğimiz İnceoğlu Tam Buğday Unumuz yüksek lif içeriğiyle lezzet ve sağlık isteyenlerin ilk tercihi olmaktadır. Müşterilerimizin talebi doğrultusunda kaba ve ince olarak servis edilebilir.',
                image: '/images/products/4.png',
                image_main: '/images/parallax/4.png',
                teslimSekilleri: ['2'],
                kullanimAlanlari: 'Tam Buğday Ekmeği, Çeşit Ekmekler',
                urunGrubu: 'Tam Buğday Ekmeği',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 12,
                    kül: 1.2,
                    energy: 230,
                    gluten: 29,
                    sed: 45,
                    stab: null,
                },
            },

            {
                title_1: 'YÖRESEL SERİ',
                title_2: 'Tandır Ekmeklik',
                url: 'tandir-ekmeklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Tandıra vurulduğunda yapışan öte yandan işlenilebilirliği yüksek İnceoğlu Özel Amaçlı Tandırlık Un siz değerli müşterilerimiz için özel reçetelendirilmiştir.',
                image: '/images/products/tandir.png',
                image_main: '/images/parallax/tandir.png',
                teslimSekilleri: ['2'],
                kullanimAlanlari: 'Tandır Ekmeği, Isparta Ekmeği',
                urunGrubu: 'Tandır Ekmeği',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 12,
                    kül: 1.2,
                    energy: 230,
                    gluten: 29,
                    sed: 45,
                    stab: null,
                },
            },
        ],
    },

    {
        name: 'Lüks Seri',
        url: 'luks-serisi',
        icon: '/images/menu/4.svg',
        image_main: '/images/parallax/16.png', // /images/products/16.png => /images/parallax/16.png
        images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],

        products: [
            {
                title_1: 'LÜKS SERİSİ',
                title_2: 'Baklava Böreklik',
                url: 'baklavalik-ve-boreklik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Baklava ve börekleriniz için uygun Pamukkale Serisi altında yer alan Baklavalık & Böreklik unumuz yüksek protein, düşük kül oranına sahiptir. Uzayabilirliği yüksek, lastiklenmisi düşüktür. Rahat ama kuvvetli bir hamur yapısı sağlar. Çıtır baklavalar, incecik börekler için idealdir.',
                image: '/images/products/16.png',
                image_main: '/images/parallax/16.png',
                teslimSekilleri: ['3', '4', '5'],
                kullanimAlanlari: 'Baklava, Börek Çeşitleri, Kruvasan, Milföy, Boyoz',
                urunGrubu: 'Baklava & Börek, El Yufkası',
                pdf: '',
                parallaxText1: 'Çıtır Çıtır Baklavalar için',
                parallaxText2: 'Zanaatkarların Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 12,
                    kül: 0.60,
                    energy: 280,
                    gluten: 28,
                    sed: 50,
                    stab: 10,
                },
            },

            {
                title_1: 'LÜKS SERİSİ',
                title_2: 'Makina Yufkalık',
                url: 'makine-yufkalik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Hem kontüne yufka hatları için hem de döner tablalı makinalar için Pamukkale Serisi altında yer alan Makina Yufkalık unumuz yüksek performans göstermektedir. Yüksek protein, düşük kül oranına sahiptir. Uzayabilirliği yüksek, lastiklenmisi düşüktür. Rahat ama kuvvetli bir hamur yapısı sağlar. İncecik yufka hamurları elde etmenizi sağlar.',
                image: '/images/products/14.png',
                image_main: '/images/parallax/14.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Baklava, Börek Çeşitleri, Kruvasan, Milföy, Boyoz',
                urunGrubu: 'Makina Yufkası',
                pdf: '',
                parallaxText1: 'İncecik Çıtır Yufkalarda​',
                parallaxText2: 'Börekçimin Tercihi',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },

            {
                title_1: 'LÜKS SERİSİ',
                title_2: 'Sarı Sert Buğday Unu',
                url: 'sari-sert-bugday-unu',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Anadolu’nun en kaliteli buğdaylarının paçalından oluşan Özel Amaçlı Sarı Sert Buğday Unu ‘’00’’ ustalarımız için özel olarak reçetelendirilmiştir. Makarnalık ve ekmeklik buğdayların bir harmoni oluşturduğu Lüks Serisi unumuz, ustaların yeni gözdelerinden.',
                image: '/images/products/12.png',
                image_main: '/images/parallax/12.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Alaşehir Ekmeği, Sarı Ekmek, Kalecik Ekmeği',
                urunGrubu: 'Lüks Ekmek',
                pdf: '',
                parallaxText1: 'Lezzetli Sarı Ekmekler için​',
                parallaxText2: 'Erbabının Tercihi​',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },

            {
                title_1: 'LÜKS SERİSİ',
                title_2: 'Sarı Sert Bazlamalık',
                url: 'sari-sert-bazlamalik-bugday-unu',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: 'Özel Amaçlı Sarı Sert Buğday Unu ‘’0’’ bazlama imalatçıları ve erişte fabrikaları için özel olarak geliştirilmiştir. Durum buğdayından gelen sarısı, yüksek protein & randıman oranı ile yumuşacık ve hacimli bazlamalar elde etmenizi sağlar.',
                image: '/images/products/bazlama.png',
                image_main: '/images/parallax/bazlama.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Bazlama, Erişte, Makarna',
                urunGrubu: 'Bazlama',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },


            {
                title_1: 'ÖZEL AMAÇLI BUĞDAY UNU',
                title_2: 'Tatlılık',
                url: 'tatlilik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: '-',
                image: '/images/products/tatlilik.png',
                image_main: '/images/parallax/bazlama.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Bazlama, Erişte, Makarna',
                urunGrubu: 'Bazlama',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },



            {
                title_1: 'SEMOLINA',
                title_2: 'Semolina-İrmik',
                url: 'semolina-irmik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: '-',
                image: '/images/products/bazlama.png',
                image_main: '/images/parallax/bazlama.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Bazlama, Erişte, Makarna',
                urunGrubu: 'Bazlama',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },

            
        ],
    },


















    {
        name: 'Lifli Seri',
        url: 'lifli-seri',
        icon: '/images/menu/a.svg',
        image_main: '/images/parallax/16.png', 
        images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],

        products: [
            



            {
                title_1: 'KEPEK',
                title_2: 'Kepek',
                url: 'semolina-irmik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: '-',
                image: '/images/products/kepek.png',
                image_main: '/images/parallax/bazlama.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Bazlama, Erişte, Makarna',
                urunGrubu: 'Bazlama',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },


            {
                title_1: 'RAZMOL',
                title_2: 'Razmol',
                url: 'semolina-irmik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: '-',
                image: '/images/products/razmol.png',
                image_main: '/images/parallax/bazlama.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Bazlama, Erişte, Makarna',
                urunGrubu: 'Bazlama',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },


            {
                title_1: 'Bonkalite',
                title_2: 'Bonkalite',
                url: 'semolina-irmik',
                content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',
                productText: '-',
                image: '/images/products/bonkalite.png',
                image_main: '/images/parallax/bazlama.png',
                teslimSekilleri: ['2', '4', '5'],
                kullanimAlanlari: 'Bazlama, Erişte, Makarna',
                urunGrubu: 'Bazlama',
                pdf: '',
                parallaxText1: '',
                parallaxText2: '',
                images_inner: ['/images/products/kirmizi.png', '/images/products/sari.png', '/images/products/mor.png'],
                ibre: {
                    protein: 0,
                    kül: 0,
                    energy: 0,
                    gluten: 0,
                    sed: 0,
                    stab: null,
                },
            },



            
        ],
    },
];
