import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const BakingSquare = () => {
  const squareControls = useAnimation();
  const textControls = useAnimation();
  const containerControl = useAnimation();
  ;


  

  useEffect(() => {
    const startAnimation = async () => {
 
      await squareControls.start({
        scale: 1,
        rotate: '720deg',
        transition: { duration: 1.5, ease: [0.6, -0.05, 0.01, 0.99] },
      });

     
      await squareControls.start({
        x: 0,
        scale: 1,
        rotate: ['765deg', '760deg','765deg'],
        transition: { duration: 0.5, ease: 'backOut' }, 
      });

 
      await textControls.start({
        x: 0,
        opacity: 1,
        transition: { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }, 
      });

      
      await squareControls.start({
        scale: [1, 1.1, 1],
        opacity: [1, 0.7, 1],
        transition: { duration: 1, ease: 'anticipate' }, 
      });

   
      await textControls.start({
        opacity: [1, 0.7, 1],
        transition: { duration: 1, ease: [0.4, 0, 0.2, 1] }, 
      });

       containerControl.start({
scale:0.4,
transition: { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }, 
      });

 

   

    };

    startAnimation();
  }, [squareControls, textControls]);

  return (
    <div className='w-full  bg-black space-y-16 px-8 md:px-16 lg:px-36 py-20 md:py-28 '>
      <div className='h-full w-full flex flex-col justify-center  space-y-20 '>
    <motion.div  animate={containerControl} className=' flex relative pt-32'>
      <motion.img
        src='/images/baking-square/baking-square.svg'
        className='w-[41.8%] origin-center object-contain'
        animate={squareControls}
        initial={{x:'70%',scale:0}}
      />
      <motion.img
        src='/images/baking-square/baking-square-text.png'
        className='w-[58.2%] object-contain'
        animate={textControls}
        initial={{opacity:0,x:'100%'}}
      />
    </motion.div>



    <div className='grid grid-cols-2 gap-20'>
      <div className='  border-inceoglunYellow1 border'>
        <img src='/images/baking.png' className='h-full w-full object-contain'/>
      </div>
      <div className='  text-gray-300'>
        <p className='text-xl font-light leading-8'>Fırıncılık Deneme Merkezimiz açıldığında ilk amacımız ekmeklik buğday unlarımızın kalitesini günlük olarak denemek ve endüstriyel fırıncılık endüstrisindeki müşterilerimize sürdürülebilir kalitede hammadde sağlamak olmuştur. Artan ürün çeşitliliğiyle birlikte daha fazla proses ve son ürüne hizmet edebilmek adına Baking Square’de bir çok ürünün denemelerini organize edebiliyoruz. Müşterilerimiz isterlerse burada demo çalışmaları düzenleyebiliyor veya onların talepleri doğrultusunda özel ürün çalışmalarımızı Kalite Ekibimiz ile birlikte burada organize ediyoruz.</p>
      </div>
    </div>
 


    
    </div>
    </div>
  );
};

export default BakingSquare;
