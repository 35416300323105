import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import React, { useLayoutEffect, useRef } from 'react'


const Home37 = ({ setHeaderTransparent }) => {
  const specialWheatContainer = useRef()

  useLayoutEffect(() => {
    setHeaderTransparent(true)

    return (() => setHeaderTransparent(false))
  }, [])


  useGSAP(
    () => {


      gsap.to(".c", {


        duration: 5,
        opacity: 1

      });


      // gsap.to(".special-wheat-1", { y:20,x:-80})
      // gsap.to(".special-wheat-2", { y:20,x:-60})
      // gsap.to(".special-wheat-3", { y:20,x:-40})
      // gsap.to(".special-wheat-4", { y:40,x:-40})
      // gsap.to(".special-wheat-5", { y:20})
      // gsap.to(".special-wheat-6", { y:20,x:20})
      // gsap.to(".special-wheat-7", { y:20,x:40})
      // gsap.to(".special-wheat-8", { y:20,x:40})
      // gsap.to(".special-wheat-9", { y:20,x:40})




     



    
      
      
      
      // var tl = gsap.timeline({ defaults: { duration: 1} });
      // tl.to(".special-wheat-1", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:1.1},0)
      // tl.to(".special-wheat-2", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:1.5},0)
      // tl.to(".special-wheat-3", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:0.9},0)
      // tl.to(".special-wheat-4", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true},0)
      // tl.to(".special-wheat-5", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:0.5},0)
      // tl.to(".special-wheat-6", { scale:1.2 ,translateX:10,duration:2,repeat:-1,yoyo:true,delay:0.2},0)
      // tl.to(".special-wheat-7", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:0.4},0)
      // tl.to(".special-wheat-8", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:0.1},0)
      // tl.to(".special-wheat-9", { scale:1.2 ,translateX:10,opacity:0.5,duration:2,repeat:-1,yoyo:true,delay:1},0)




      let sw1 = gsap.timeline({
        scrollTrigger: {
          trigger: ".spacialWheat",
          start: "top top",
          end: "+=500",
          scrub: 5,
          pin: true,




        }
      });

      
      sw1.to(".special-wheat-img", { yPercent: -100, opacity: 0, scale: 0.5 }, 0)
        .to(".special-wheat-1", { y: -100, x: 400 ,scale: 1}, 0)
        .to(".special-wheat-2", { y: -100, x: 300, scale: 0.5 }, 0)
        .to(".special-wheat-3", { y: -100, x: 200, scale: 0.2 }, 0)
        .to(".special-wheat-4", { y: -200, x: 200, scale: 0.2 }, 0)
        .to(".special-wheat-5", { y: -100, scale: 0.5 }, 0)
        .to(".special-wheat-6", { y: -100, x: -100, scale: 0.2 }, 0)
        .to(".special-wheat-7", { y: -100, x: -200 ,scale: 1}, 0)
        .to(".special-wheat-8", { y: -100, x: -200, scale: 0.5 }, 0)
        .to(".special-wheat-9", { y: -100, x: -200, scale: 0.2 }, 0)
        .to(".special-wheat-9", { y: -100, x: -200, scale: 0.2 }, 0)
        .to(".special-package", { y: -300, opacity: 1 ,scale: 1}, 0)


        .to(".special-wheat-1", { y: 50, x: 500, opacity: 0,scale: 1}, 0.5)
        .to(".special-wheat-3", { y: 200, x: 250, opacity: 0 , scale: 0.2}, 0.5)
        .to(".special-wheat-5", { y: 200, opacity: 0 , scale: 0.5}, 0.5)
        .to(".special-wheat-2", { y: 50, x: 400, opacity: 0 , scale: 0.5}, 0.5)
        .to(".special-wheat-4", { y: 70, opacity: 0 , scale: 0.2}, 0.5)
        .to(".special-wheat-6", { y: 50, x: -170, opacity: 0 , scale: 0.2 }, 0.5)
        .to(".special-wheat-7", { y: 300, opacity: 0 ,scale: 1}, 0.5)
        .to(".special-wheat-8", { y: 300, x: -300, opacity: 0 , scale: 0.5 }, 0.5)
        .to(".special-wheat-9", { y: 200, x: -300, opacity: 0 , scale: 0.2}, 0.5)
        .to(".special-package", { scale: 1.9 },">")
        .to(".special-package", { y: -600, scale: 1 },">")
        .to(".special-package", { rotate: 5 },">")
        .to(".special-package", { rotate: -5 },">")
        .to(".special-package", { rotate: 0 },">")
        .to(".special-crashed-bread", { opacity: 1, y: 200 },">")
        .to(".special-package", { y: -1000, opacity: 0 },">")
        .to(".special-crashed-bread", { scale: 7, y: 100 },">")
















    }, [specialWheatContainer]
  )

  return (
    <div ref={specialWheatContainer} className=' h-screen  relative  spacialWheat c opacity-0' >
      <div className='h-screen' style={{background:"rgb(69,40,0)",background:"radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)"}}></div>
      <img
        src="images/special-wheats/Rumeli.png"
        alt="logo"
        className=" object-contain  absolute max-h-[12.5vw] left-[33%] top-[35.7%] special-wheat-img"
      />


      <img
        src="images/special-wheats/crashed-breads.png"
        alt="logo"
        className=" object-contain  absolute max-h-[4.5vw] left-[46.5%] top-[30.7%] opacity-0 special-crashed-bread"
      />






      <img
        src="images/special-wheats/1.png"
        alt="logo"
        className="  object-contain  absolute max-h-[6.339vw] left-[15.53%] top-[52.9117%] special-wheat-1"
      />
      <img
        src="images/special-wheats/2.png"
        alt="logo"
        className=" object-contain  absolute max-h-[10.452vw] left-[15.83%] top-[45.3125%] special-wheat-2"
      />
      <img
        src="images/special-wheats/3.png"
        alt="logo"
        className=" object-contain  absolute max-h-[20.052vw] left-[21.27%] top-[16.5881%] special-wheat-3"
      />
      <img
        src="images/special-wheats/4.png"
        alt="logo"
        className=" object-contain  absolute max-h-[17.289vw] left-[26.24%] top-[52.857%] special-wheat-4"
      />
      <img
        src="images/special-wheats/5.png"
        alt="logo"
        className=" object-contain  absolute max-h-[5.607vw] left-[45.99%] top-[34.5%] special-wheat-5"
      />
      <img
        src="images/special-wheats/6.png"
        alt="logo"
        className=" object-contain  absolute max-h-[10.547vw] left-[54.67%] top-[49.0833%] special-wheat-6"
      />
      <img
        src="images/special-wheats/7.png"
        alt="logo"
        className=" object-contain  absolute max-h-[5.4895vw] left-[59.36%] top-[26.9317%] special-wheat-7"
      />
      <img
        src="images/special-wheats/8.png"
        alt="logo"
        className=" object-contain  absolute max-h-[7.40475vw] left-[68.07%] top-[28.335%] special-wheat-8"
      />
      <img
        src="images/special-wheats/9.png"
        alt="logo"
        className=" object-contain  absolute max-h-[6.1875vw] left-[66.26%] top-[51%] special-wheat-9"
      />


      <img
        src="images/general/22.png"
        alt="logo"
        className=" object-contain  absolute max-h-[12vw] left-[45%] top-[100%] special-package opacity-0"
      />

    </div>

  )
}

export default Home37