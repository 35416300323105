import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import CircularProgress from '../components/CircularProgress';


import { Parallax } from 'react-scroll-parallax';
import { TypeAnimation } from 'react-type-animation';
import { Link, useParams } from 'react-router-dom';


import { DATA } from '../data/data-urunler'


// protein : 12,
// kul :2












const UrunlerContainer = ({ setHeaderTransparent }) => {

    const params = useParams()


    const paramsId = params.id


    const [toggle, setToggle] = useState(0)

     console.log(paramsId)

    useLayoutEffect(() => {
        switch (params.id) {
            case 'turuncu-ekmeklik':
                setToggle(0)
                break;
            case 'yesil-ekmeklik':
                setToggle(1)
                break;
            case 'kirmizi-ekmeklik':
                setToggle(2)
                break;
            case 'sari-ekmeklik':
                setToggle(3)
                break;
            case 'kurukek-pasta':
                setToggle(4)
                break;
                case 'pastalik-boreklik':
                    setToggle(12)
                    break;
            case 'pogaca-acma-borek':
                setToggle(5)
                break;
            case 'unlu-mamuller':
                setToggle(6)
                break;
            case 'pidelik':
                setToggle(7)
                break;

                case 'simitlik':
                    setToggle(13)
                    break;

            case 'yufkalık':
                setToggle(9)
                break;

                case 'tam-bugday-unu':
                    setToggle(10)
                    break;

                    case 'baklavalik-ve-boreklik':
                        setToggle(11)
                        break;


                        case 'makine-yufkalik':
                            setToggle(14)
                            break;

                            case 'sari-sert-bugday-unu':
                                setToggle(15)
                                break;


            default:
                break;
        }

        setHeaderTransparent(true)

        return (() => setHeaderTransparent(false))
    }, [params])






    return (
        <div className=' pt-20 md:pt-28   flex flex-col justify-between  ' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>

            {
                DATA.map((item) => {
                    return (
                        toggle === item.id && <> <div >
                            <div className='space-y-20 px-8 md:px-16 lg:px-36 3xl:container mx-auto py-10'>
                                {/* <TypeAnimation
                                    sequence={[

                                        `Ürünler / ${item.type} /${item.header1} / ${item.header2}`,
                                        1000

                                    ]}
                                    cursor={false}
                                    speed={60}
                                    className='text-white animate-changeOpacity'
                                    style={{ fontSize: '0.875rem' }}

                                /> */}

                                <div className='text-[0.875rem] text-white animate-changeOpacity '>{`Ürünler / ${item.type} /${item.header1} / ${item.header2}`}</div>

                                <div className='h-full  flex flex-col lg:flex-row space-x-20'>


                                    <div className='flex flex-col  space-y-2   min-w-max '>
                                        <div className='flex flex-col space-y-2'>
                                            <Link to={'firin-serisi/turuncu-ekmeklik'}><div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform`}>Fırın Serisi</div></Link>
                                            <Link to={'firin-serisi/turuncu-ekmeklik'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform  ${toggle === 0 ? 'opacity-100' : 'opacity-60'} ${toggle === 0 ? 'scale-105' : 'scale-100'} }`} >Turuncu Ekmeklik</div></Link>
                                            <Link to={'firin-serisi/yesil-ekmeklik'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 1 ? 'opacity-100' : 'opacity-60'} ${toggle === 1 ? 'scale-105' : 'scale-100'}`} >Yeşil Ekmeklik</div></Link>
                                            <Link to={'firin-serisi/kirmizi-ekmeklik'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 2 ? 'opacity-100' : 'opacity-60'} ${toggle === 2 ? 'scale-105' : 'scale-100'}`}>Kırmızı Ekmeklik</div></Link>
                                            <Link to={'firin-serisi/sari-ekmeklik'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 3 ? 'opacity-100' : 'opacity-60'} ${toggle === 3 ? 'scale-105' : 'scale-100'}`}>Sarı Ekmeklik</div></Link>
                                            {/* <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 4 ? 'scale-105' : 'scale-100'}`} onClick={() => setToggle(4)}>Tam Buğday Unu</div> */}
                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <Link to={'pastane-serisi/kurukek-pasta'}><div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform`}>Pastane Serisi</div></Link>
                                            <Link to={'pastane-serisi/kurukek-pasta'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 4 ? 'opacity-100' : 'opacity-60'}  ${toggle === 4 ? 'scale-105' : 'scale-100'}`} >Kuru Kek, Pasta</div></Link>
                                            <Link to={'pastane-serisi/pastalik-boreklik'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 12 ? 'opacity-100' : 'opacity-60'}  ${toggle === 12 ? 'scale-105' : 'scale-100'}`} >Pastalik Böreklik</div></Link>
                                            <Link to={'pastane-serisi/pogaca-acma-borek'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 5 ? 'opacity-100' : 'opacity-60'} ${toggle === 5 ? 'scale-105' : 'scale-100'}`} >Poğaça, Açma, Börek</div></Link>
                                            <Link to={'pastane-serisi/unlu-mamuller'}><div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 6 ? 'opacity-100' : 'opacity-60'} ${toggle === 6 ? 'scale-105' : 'scale-100'}`} >Unlu Mamüller</div></Link>

                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <Link to={'usta-serisi/pidelik'}> <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform  `}>Usta Serisi</div></Link>
                                            <Link to={'usta-serisi/pidelik'}>   <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 7 ? 'opacity-100' : 'opacity-60'}  ${toggle === 7 ? 'scale-105' : 'scale-100'}`} >Pidelik</div></Link>
                                            <Link to={'usta-serisi/simitlik'}>   <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 13 ? 'opacity-100' : 'opacity-60'}  ${toggle === 13 ? 'scale-105' : 'scale-100'}`} >Simitlik</div></Link>
                                            
                                        </div>

                                        <div className='flex flex-col'>
                                            <Link to={'yoresel-seri/yufkalık'}>  <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform `} >Yöresel Seri</div></Link>
                                            <Link to={'yoresel-seri/yufkalık'}>   <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 9 ? 'opacity-100' : 'opacity-60'}  ${toggle === 9 ? 'scale-105' : 'scale-100'}`} >Yufkalık</div></Link>
                                            <Link to={'yoresel-seri/tam-bugday-unu'}>   <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 10 ? 'opacity-100' : 'opacity-60'}  ${toggle === 10 ? 'scale-105' : 'scale-100'}`} >Tam Buğday Unu</div></Link>
                                        </div>



                                        <div className='flex flex-col'>
                                            <Link to={'luks-seri/baklavalik-ve-boreklik'}>    <div className={`text-base lg:text-2xl text-white cursor-pointer hover:scale-105 transition-transform `} >Lüks Seri</div></Link>
                                            <Link to={'luks-seri/baklavalik-ve-boreklik'}>     <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 11 ? 'opacity-100' : 'opacity-60'}  ${toggle === 11 ? 'scale-105' : 'scale-100'}`} >Baklavalık ve Böreklik</div></Link>
                                            <Link to={'luks-seri/makine-yufkalik'}>     <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 14 ? 'opacity-100' : 'opacity-60'}  ${toggle === 14 ? 'scale-105' : 'scale-100'}`} >Makine Yufkalık</div></Link>
                                            <Link to={'luks-seri/sari-sert-bugday-unu'}>     <div className={`text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform ${toggle === 15 ? 'opacity-100' : 'opacity-60'}  ${toggle === 15 ? 'scale-105' : 'scale-100'}`} >Sarı Sert Bugday Unu</div></Link>

                                        </div>





                                    </div>

                                    <div className=' w-[23rem]  '>
                                        <img src={`${item.image}`} className="animate-changeOpacity w-full object-contain object-top" alt="logo" />
                                    </div>



                                    <div className='flex    '>
                                        <div className='flex flex-col space-y-10'>
                                            <div className='flex flex-col'>

                                                <TypeAnimation
                                                    sequence={[

                                                        `${item.header1}`,
                                                        1000

                                                    ]}
                                                    cursor={false}
                                                    speed={1}
                                                    className='text-[3.375rem] font-bold leading-none text-white animate-changeOpacity1'
                                                    style={{ fontSize: '3.375rem' }}

                                                />

                                                <TypeAnimation
                                                    sequence={[

                                                        `${item.header2}`,
                                                        1000,

                                                    ]}
                                                    cursor={false}
                                                    speed={1}
                                                    className='text-[3.375rem] leading-none text-white animate-changeOpacity1'
                                                    style={{ fontSize: '3.375rem' }}

                                                />
                                                {/* <h5 className='text-[3.375rem] font-bold leading-none text-white animate-changeOpacity1' >{item.header1}</h5> */}
                                                {/* <h5 className='text-[3.375rem] leading-none text-white animate-changeOpacity1'>{item.header2}</h5> */}

                                            </div>
                                            <div className='w-full flex flex-col items-start  space-y-5 '>
                                                <div className='flex  w-full justify-start space-x-5 animate-changeOpacity1'>
                                                    <CircularProgress proportionedProgressPercentage={item.ibre.protein * 100 / 12} progressPercentage={item.ibre.protein} name={'PROTEIN'} />
                                                    <CircularProgress proportionedProgressPercentage={item.ibre.kül * 100 / 2} progressPercentage={item.ibre.kül} name={'KÜL %'} />
                                                    <CircularProgress proportionedProgressPercentage={item.ibre.energy * 100 / 300} progressPercentage={item.ibre.energy} name={'ENERJİ'} />
                                                </div>

                                                <div className='flex  w-full justify-start space-x-5 animate-changeOpacity1'>
                                                    <CircularProgress proportionedProgressPercentage={item.ibre.gluten * 100 / 35} progressPercentage={item.ibre.gluten} name={'GLUTEN'} />
                                                    <CircularProgress proportionedProgressPercentage={item.ibre.sed * 100 / 65} progressPercentage={item.ibre.sed} name={'SEDİMANTASYON'} />
                                                    {
                                                        item.ibre.stab && (<CircularProgress operator={'+'} proportionedProgressPercentage={item.ibre.protein * 100 / 18} progressPercentage={item.ibre.stab} name={'STABİLİTE'} />)
                                                    }

                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                    <div className='cursor-pointer  transition-transform  flex-1 flex items-end justify-end '>
                                        <img src="/images/products/pdf.svg" className="w-16 lg:w-auto animate-contentIcons4 hover:scale-105 duration-300" alt="logo" />

                                    </div>
                                </div>

                                <div className='w-full    flex justify-center text-white animate-changeOpacity1'>
                                    <h5 className='w-1/2'>Lorem ipsum dolor sit ametsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h5>
                                </div>

                            </div>






                        </div>
                            <div>
                                <div className=' h-[600px] overflow-hidden px-8 md:px-16 lg:px-36 relative '>
                                    <Parallax speed={100000} translateY={[-500, 500]}  >

                                        <img src='/images/products/inceoglun.jpg' className='w-full' />


                                    </Parallax>




                                </div>

                                <div className='h-40 w-full  relative flex justify-between z-20   '>
                                    <div className='h-full left-0  -top-20 flex justify-between absolute w-full px-8 md:px-16 lg:px-36'>
                                        <img src='/images/wheat-3/esperya.png' className='h-full' />
                                        <img src='/images/wheat-3/lucilla.png' className='h-full' />
                                        <img src='/images/wheat-3/nagev.png' className='h-full' />
                                        <img src='/images/wheat-3/rumeli.png' className='h-full' />
                                        <img src='/images/wheat-3/ziya.png' className='h-full' />
                                    </div>
                                </div>



                            </div>



                            <div className=' w-full  grid grid-flow-col px-8 md:px-16 lg:px-36 3xl:container mx-auto'>
                                {item.type === 'Fırın Serisi' &&
                                    <>
                                        <div className='grid-cols-3 bg-slate-400 '>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>
                                        <div className='grid-cols-3 bg-stone-600'>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>
                                        <div className='grid-cols-3 bg-orange-300 '>
                                            <img src='/images/products/sari.png' className='w-full' />
                                        </div>
                                        <div className='grid-cols-3 bg-orange-800 '>
                                            <img src='/images/products/mor.png' className='w-full' />
                                        </div>
                                    </>
                                }

                                {item.type === 'Pastane Serisi' &&
                                    <>
                                        <div className='grid-cols-4 bg-slate-400 '>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>
                                        <div className='grid-cols-4 bg-stone-600 '>
                                            <img src='/images/products/sari.png' className='w-full' />
                                        </div>
                                        <div className='grid-cols-4 bg-orange-300  '>
                                            <img src='/images/products/mor.png' className='w-full' />
                                        </div>

                                    </>
                                }

                                {item.type === 'Usta Serisi' &&
                                    <>
                                        <div className='grid-cols-12 bg-slate-400'>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>


                                    </>
                                }

                                {item.type === 'Yöresel Seri' &&
                                    <>
                                        <div className='grid-cols-6 bg-slate-400'>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>
                                        <div className='grid-cols-6 bg-stone-600'>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>


                                    </>
                                }

                                {item.type === 'Lüks Seri' &&
                                    <>
                                        <div className='grid-cols bg-slate-400'>
                                            <img src='/images/products/kirmizi.png' className='w-full' />
                                        </div>


                                    </>
                                }



                            </div>
                        </>
                    )
                })
            }


















            {/* <div className='h-96 w-full bg-red-500 grid grid-flow-col px-8 md:px-16 lg:px-36 3xl:container mx-auto'>

                <div className='grid-cols-3 bg-slate-400'>

                </div>
                <div className='grid-cols-3 bg-stone-600'>

                </div>
                <div className='grid-cols-3 bg-orange-300'>

                </div>
                <div className='grid-cols-3 bg-orange-800'>

                </div>


            </div> */}




        </div>
    )
}

export default UrunlerContainer