import React, { useRef } from 'react';
import Slider from '../components/Slider';
import HomeBanner from '../components/HomeBanner';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import HomeProduct from '../components/HomeProduct';
import Section1 from '../components/home-components/Section1';
import Section2 from '../components/home-components/Section2';
import Section3 from '../components/home-components/Section3';
import Section4 from '../components/home-components/Section4';
import Section5 from '../components/home-components/Section5';
import Section6 from '../components/home-components/Section6';
import Section7 from '../components/home-components/Section7';
import YouTube, { YouTubeProps } from 'react-youtube';
import VideoSection from '../components/home-components/VideoSection';
import HomeBanner2 from '../components/home-components/HomeBanner2';
import WheathSection from '../components/home-components/WheathSection';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const Container = useRef();

  useGSAP(() => {
    ScrollTrigger.getAll().forEach(trigger => trigger.kill());

    const sections = gsap.utils.toArray(".panel");

    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".xSlider",
        pin: true,
        scrub: 1,
        snap: 1 / (sections.length - 1),
        start: "top top",
        end: "bottom",
      },
    });

    gsap.from(".banner-wheat-1", {
      yPercent: -20,
      ease: "none",
      scrollTrigger: {
        trigger: ".homeBannerContainer",
        scrub: 2
      },
    });

    gsap.from(".banner-wheat-2", {
      yPercent: 20,
      ease: "none",
      scrollTrigger: {
        trigger: ".homeBannerContainer",
        scrub: 2
      },
    });

    gsap.from(".banner-wheat-3", {
      yPercent: 20,
      ease: "none",
      scrollTrigger: {
        trigger: ".homeBannerContainer",
        scrub: 2
      },
    });

    gsap.from(".banner-wheat-4", {
      yPercent: 40,
      ease: "none",
      scrollTrigger: {
        trigger: ".homeBannerContainer",
        scrub: 2
      },
    });

    gsap.from(".banner-wheat-5", {
      yPercent: 30,
      ease: "none",
      scrollTrigger: {
        trigger: ".homeBannerContainer",
        scrub: 2
      },
    });

    // const videoSection = gsap.utils.toArray(".videoPanel");

    // gsap.to(videoSection, {
    //   xPercent: -100 * (videoSection.length - 1),
    //   ease: "none",
    //   scrollTrigger: {
    //     trigger: ".videoSecitonContainer",
    //     pin: true,
    //     scrub: 1,
    //     snap: 1 / (videoSection.length - 1),
    //     start: "top top",
    //     end: "bottom",
    //   },
    // });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".aboutuscontainer",
        start: "top top",
        end: "+=1000",
        scrub: 1,
        pin: true,
      }
    });

    tl.from(".aboutusimg", { scale: 2, opacity: 0, translateX: '100%', duration: 2 });
    tl.from(".aboutuslogo", { opacity: 0, duration: 2 });
    tl.from(".aboutuscontent", { opacity: 0, translateX: 100, duration: 2 });

    let sw2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".spacialWheat2",
        start: "top top",
        end: "+=1000",
        scrub: 5,
        pin: true,
      }
    });

    sw2.to(".special-wheat-img", { yPercent: -30, opacity: 0, scale: 0.5 }, 0)
      .to(".special-wheat-1", { y: -50, x: 450, scale: 1 }, 0)
      .to(".special-wheat-2", { y: -50, x: 150, scale: 0.5 }, 0)
      .to(".special-wheat-3", { y: -50, x: 100, scale: 0.2 }, 0)
      .to(".special-wheat-4", { y: -50, x: 100, scale: 0.2 }, 0)
      .to(".special-wheat-5", { y: -50, scale: 0.5 }, 0)
      .to(".special-wheat-6", { y: -50, x: -50, scale: 0.2 }, 0)
      .to(".special-wheat-7", { y: -50, x: -100, scale: 1 }, 0)
      .to(".special-wheat-8", { y: -50, x: -100, scale: 0.5 }, 0)
      .to(".special-wheat-9", { y: -50, x: -100, scale: 0.2 }, 0)
      .to(".special-wheat-9", { y: -50, x: -100, scale: 0.2 }, 0);

    gsap.from(".arge-img", {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: ".arge-container",
      },
    });

    gsap.from(".arge-content", {
      opacity: 0, translateX: 100, duration: 1,
      ease: "none",
      scrollTrigger: {
        trigger: ".arge-container",
      },
    });

    gsap.utils.toArray(".animCounter").forEach(box => {
      gsap.from(box, {
        textContent: "0",
        duration: 3,
        ease: "power1.inOut",
        modifiers: {
          textContent: value => formatNumber(value, 0) + "+"
        },
        scrollTrigger: {
          trigger: box,
          start: "100px 80%",
          end: "+=100",
          toggleActions: "play none none reverse",
          end: "top 100%",
          once: true
        }
      });
    });

    function formatNumber(value, decimals) {
      let s = (+value).toLocaleString('en-US').split(".");
      return decimals ? s[0] + "." + ((s[1] || "") + "00000000").substr(0, decimals) : s[0];
    }

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  });

  return (
    <div className=' ' ref={Container}>
      <div className=' '>
        <Slider />
        <Section1 />
        <HomeBanner2 />
        {/* <VideoSection /> */}
        <Section2 />
        <WheathSection />
        <Section5 />
        <Section6 />




        
        {/* <HomeBanner /> */}
        {/* <Section7 /> */}
        {/* TRUCK */}
        {/* <Section3 /> */}
        {/* <HomeProduct /> */}
        {/* <Section4 /> */}
        {/* -----------------AR-GE-----END-------------------------------------------- */}
        {/* <div className='h-16 w-full  flex justify-center items-center'>
          <img src="images/icons/top-arrow.svg" className="" alt="top-arrow" />
        </div> */}
      </div>
    </div>
  )
}

export default Home;
