import React, { useState, useEffect } from 'react';

const Bugday = () => {
    const [hovered, setHovered] = useState(null);
    const [animatedText, setAnimatedText] = useState(''); // This will hold the displayed text with animation

    // Function to determine the displayed letter
    const getLetter = () => {
        switch (hovered) {
            case 1:
                return 'POLATLI';
            case 2:
                return 'SÖKE';
            case 3:
                return 'AYDIN';
            case 4:
                return 'TRAKYA';
            case 5:
                return 'DENİZLİ';
            default:
                return '';
        }
    };

    // Update the animated text when hovered changes
    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimatedText(getLetter());
        }, 200); // This delay gives the fade-out effect before changing text

        return () => clearTimeout(timeout);
    }, [hovered]);

    return (
        <div className='py-20 md:py-28 flex items-center justify-center'>
            <div className='flex flex-col items-center justify-center h-[calc(100vh-4rem)] relative'>
                <div className='flex w-full'>
                    <img
                        src='/images/bugday/1.png'
                        onMouseEnter={() => setHovered(1)}
                        onMouseLeave={() => setHovered(null)}
                        className={`w-[43.81%] z-10 hover:scale-105 transition-all duration-300 cursor-pointer ${hovered === 1 ? 'opacity-100' : 'opacity-60'}`}
                    />
                    <img
                        src='/images/bugday/2.png'
                        onMouseEnter={() => setHovered(2)}
                        onMouseLeave={() => setHovered(null)}
                        className={`w-[21.02%] z-10 hover:scale-105 transition-all duration-300 cursor-pointer ${hovered === 2 ? 'opacity-100' : 'opacity-60'}`}
                    />
                    <img
                        src='/images/bugday/3.png'
                        onMouseEnter={() => setHovered(3)}
                        onMouseLeave={() => setHovered(null)}
                        className={`w-[35.17%] z-10 hover:scale-105 transition-all duration-300 cursor-pointer ${hovered === 3 ? 'opacity-100' : 'opacity-60'}`}
                    />
                </div>

                <div className='flex w-full'>
                    <img
                        src='/images/bugday/4.png'
                        onMouseEnter={() => setHovered(4)}
                        onMouseLeave={() => setHovered(null)}
                        className={`w-[50.52%] z-10 hover:scale-105 transition-all duration-300 cursor-pointer ${hovered === 4 ? 'opacity-100' : 'opacity-60'}`}
                    />
                    <img
                        src='/images/bugday/5.png'
                        onMouseEnter={() => setHovered(5)}
                        onMouseLeave={() => setHovered(null)}
                        className={`w-[49.48%] z-10 hover:scale-105 transition-all duration-300 cursor-pointer ${hovered === 5 ? 'opacity-100' : 'opacity-60'}`}
                    />
                </div>

                {/* Text with animation */}
                <div
                    className={`absolute text-[10vw] text-white transition-all duration-500 ease-in-out opacity-100 montSerrat transform ${
                        hovered ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 '
                    }`}
                >
                    {animatedText}
                </div>
            </div>
        </div>
    );
};

export default Bugday;
