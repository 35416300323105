import React from 'react'

const HomeBanner2 = () => {
  return (
   
    <div className='relative homeBannerContainer hidden md:block h-[80vh] '>

      <img src='/images/home-banner/1.png' className='w-full'/>
      <img src='/images/home-banner/2.png' className='absolute top-0 left-0 banner-wheat-5 w-full'/>
      <img src='/images/home-banner/3.png' className='absolute top-0 left-0 banner-wheat-2 w-full'/>
      <img src='/images/home-banner/4.png' className='absolute top-0 left-0 banner-wheat-1 w-full'/>
      <img src='/images/home-banner/5.png' className='absolute top-0 left-0 banner-wheat-4 w-full'/>
      <img src='/images/home-banner/6.png' className='absolute top-0 left-0 banner-wheat-3 w-full'/>
    </div>

  )
}

export default HomeBanner2