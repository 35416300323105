import React from 'react'
import Guncelleniyor from './Guncelleniyor'
import { motion } from "framer-motion"
const InsanKaynaklariPolitikalarimiz = () => {

  
  const list = {
    visible: {
        opacity: 1,

        transition: {
            when: "beforeChildren",
            staggerChildren: 0.2,
        },
    },
    hidden: { opacity: 0 },

}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <div className='min-h-screen   w-full px-8 md:px-16 lg:px-36 pt-20 md:pt-28 ' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>
      <div className='h-full w-full flex flex-col justify-center space-y-20 pt-20'>

        <motion.div variants={itemText} className='text-white space-y-4 w-full md:w-2/3'>

          <h5 className='text-4xl font-bold mb-14 montSerrat'>İNSAN KAYNAKLARI POLİTİKALARIMIZ</h5>
          <div className='space-y-5 text-gray-300'>
            <p>İş, bir değer yaratmak için çalışmaktır. Bunu her gün aynı özenle bir takım halinde yapmak ve çözümün ortağı olma motivasyonunu sağlamak en büyük ilkemizdir. Proaktif, işini tutkuyla yapan, uzun vadeli hedeflere ulaşmak için kurum kültürünü esas alarak bağlılıkla çalışan ekip arkadaşlarımızın olduğu bir ekosistemin sürekliliğini sağlamak en büyük gayemizdir.</p>
            <p></p>
            <div className='h-px w-full bg-gray-300'></div>
            <p>Deneyime değer veren, bununla birlkte yeniliklere ve dinamizme güvenen,
            </p>
            <div className='h-px w-full bg-gray-300'></div>
            <p>Yetkinlikler yaratmaya çalışan, kapsamları belirlenmiş insiyatif alanlarına sahip,​

​
            </p>
            <div className='h-px w-full bg-gray-300'></div>
            <p>Sorumluluk ve görev bilinci yüksek, öz yönelimci takım oyuncularını destekleyen bir iş ekosistemini sürdürülebilir kılmaktır.​</p>
          </div>
        </motion.div>


      </div>
    </div>
  )
}

export default InsanKaynaklariPolitikalarimiz