import React from 'react'

const WheathSection = () => {
  return (

    <div className='relative spacialWheat2 hidden md:block  w-screen'>
      <div className='h-screen flex items-center pt-56  w-screen '>
        <div className='relative h-screen '>
          <img src='/images/wheatsection/back.png' className='w-screen object-contain' />
          <img src='/images/wheatsection/1.png' className='special-wheat-1 absolute left-0 top-0 z-10 w-screen ' />
          <img src='/images/wheatsection/2.png' className='special-wheat-2 absolute left-0 top-0 z-10 w-screen' />
          <img src='/images/wheatsection/3.png' className='special-wheat-3 absolute left-0 top-0 z-50 w-screen' />
          <img src='/images/wheatsection/4.png' className='special-wheat-4 absolute left-0 top-0 z-10 w-screen' />
          <img src='/images/wheatsection/5.png' className='special-wheat-5 absolute left-0 top-0 z-50 w-screen' />
          <img src='/images/wheatsection/6.png' className='special-wheat-6 absolute left-0 top-0 z-50 w-screen' />
          <img src='/images/wheatsection/7.png' className='special-wheat-7 absolute left-0 top-0 z-10 w-screen' />
          <img src='/images/wheatsection/8.png' className='special-wheat-8 absolute left-0 top-0 z-10 w-screen' />
          <img src='/images/wheatsection/9.png' className='special-wheat-9 absolute left-0 top-0 z-10 w-screen' />
          <img src='/images/wheatsection/10.png' className='special-wheat-img absolute left-0 top-0 z-10 w-screen' />
        </div>
      </div>
    </div>
  )
}

export default WheathSection