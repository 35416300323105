import React from 'react'
import { Link } from 'react-router-dom'

const Section2 = () => {
  return (

    <div className=" aboutuscontainer w-full  relative   px-8 md:px-16 lg:px-36   "
    >
      <div className=' flex  items-center py-36 flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-10 3xl:container mx-auto min-h-screen'>
      <div className=" aboutusimg w-full  md:w-1/2 relative ">
        <div className='w-full relative overflow-hidden'>
          <img
            src="images/history/a1.jpg"
            className=" min-w-[834px] h-[20vh] lg:min-h-[348px] object-cover"
            alt="logo"
          />
        </div>
        <div className='  absolute z-20 -bottom-14 lg:-bottom-7 -right-7 md:-bottom-16 md:-right-16 aboutuslogo'>
          <img src="images/logos/logo2.png" class="h-28 md:h-32 " alt="logo" />

        </div>
      </div>
      <div

        className="aboutuscontent  space-y-5  w-full flex justify-center  flex-col  md:w-1/2      md:pl-20 ">
        <h6 className="text-3xl text-white font-bold">Hakkımızda</h6>
        <p className="text-white  ">
          İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet
          İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif Şirketi
          ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve 25 t/gün
          kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun yıllar
          faaliyetini sürdürmüştür.
        </p>
        <p className="text-white">
          1965 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş olarak
          Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı olmuştur. 1976
          senesinde İzmir Karayolu üzerindeki 150 t/gün kapasiteli yeni bir
          fabrika kurulmuş ve
        </p>
        <Link  to={'kurumsal/tarihce'}>
        <div className="h-10 w-[173px]  flex justify-center items-center  border border-white mt-5 hover:scale-105 transition-all duration-500">
          <h6 className="text-[10px] font-bold text-white">
            DAHA FAZLA İÇİN
          </h6>
        </div>
        </Link>
      </div>
      </div>
    </div>
  )
}

export default Section2