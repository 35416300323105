import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Isilanlari = () => {
  const [fileName, setFileName] = useState(''); // State to store the selected file name

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string().required('İsim gereklidir'),
    lastName: Yup.string().required('Soy isim gereklidir'),
    phoneNumber: Yup.string()
      .required('Telefon numarası gereklidir')
      .matches(/^[0-9]+$/, 'Sadece rakam içermelidir')
      .min(10, 'En az 10 karakter olmalı'),
    email: Yup.string().email('Geçersiz email adresi').required('Email gereklidir'),
    position: Yup.string().required('Başvurulan pozisyon gereklidir'),
    resume: Yup.mixed()
      .required('CV yüklemeniz gereklidir')
      .test('fileSize', 'Dosya boyutu çok büyük, en fazla 10MB olmalıdır', value => value && value.size <= 10485760) // 10 MB limit
      .test('fileFormat', 'Sadece PDF formatı kabul edilmektedir', value => value && value.type === 'application/pdf'),
  });

  // Handle form submission
  const handleSubmit = (values) => {
    console.log('Form Submitted:', values);
  };

  return (
    <div className='min-h-screen flex flex-col items-center w-full px-8 md:px-16 lg:px-36 pt-20 md:pt-28' style={{ background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>
      <div className='h-full w-full max-w-screen-md flex flex-col justify-center space-y-5 pt-20'>
        <h5 className="text-4xl font-bold text-white mb-10">İŞ BAŞVURUSU</h5>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            position: '',
            resume: null, // Initial value for file input
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className='space-y-5'>
              <div className='flex items-center space-x-5'>
                <h6 className='w-52 text-white'>İsim</h6>
                <div className='w-full'>
                  <Field name='firstName' placeholder='İsminizi giriniz' className='input-field py-2 px-4 w-full border border-inceoglunYellow1' />
                  <ErrorMessage name='firstName' component='div' className='text-red-500 text-sm' />
                </div>
              </div>
              <div className='flex items-center space-x-5'>
                <h6 className='w-52 text-white'>Soy İsim</h6>
                <div className='w-full'>
                  <Field name='lastName' placeholder='Soy isminizi giriniz' className='input-field py-2 px-4 w-full border border-inceoglunYellow1' />
                  <ErrorMessage name='lastName' component='div' className='text-red-500 text-sm' />
                </div>
              </div>
              <div className='flex items-center space-x-5'>
                <h6 className='w-52 text-white'>Telefon Numarası</h6>
                <div className='w-full'>
                  <Field name='phoneNumber' placeholder='Telefon numaranızı giriniz' className='input-field py-2 px-4 w-full border border-inceoglunYellow1' />
                  <ErrorMessage name='phoneNumber' component='div' className='text-red-500 text-sm' />
                </div>
              </div>
              <div className='flex items-center space-x-5'>
                <h6 className='w-52 text-white'>E-Mail Adresi</h6>
                <div className='w-full'>
                  <Field name='email' placeholder='E-Mail adresinizi giriniz' className='input-field py-2 px-4 w-full border border-inceoglunYellow1' />
                  <ErrorMessage name='email' component='div' className='text-red-500 text-sm' />
                </div>
              </div>
              <div className='flex items-center space-x-5'>
                <h6 className='w-52 text-white'>Başvurulan Pozisyon</h6>
                <div className='w-full'>
                  <Field name='position' placeholder='Pozisyonu giriniz' className='input-field py-2 px-4 w-full border border-inceoglunYellow1' />
                  <ErrorMessage name='position' component='div' className='text-red-500 text-sm' />
                </div>
              </div>

              {/* File upload field */} 
              <div className='flex items-center space-x-5'>
                <h6 className='w-52 text-white'>CV Yükle</h6>
                <div className='w-full'>
                  <input
                    id='resume'
                    name='resume'
                    type='file'
                    accept='application/pdf' // Only accept PDF files
                    className='hidden' // Hide the default file input
                    onChange={(event) => {
                      setFieldValue('resume', event.currentTarget.files[0]);
                      setFileName(event.currentTarget.files[0]?.name || ''); // Store the file name
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => document.getElementById('resume').click()} // Trigger file input on button click
                    className='py-2 px-6 bg-white text-inceoglunYellow1 hover:text-white hover:bg-inceoglunYellow1  transition-all duration-300'
                  >
                    Dosya Yükle
                  </button>
                  {fileName && <p className='text-white mt-2'>Seçilen Dosya: {fileName}</p>}
                  <ErrorMessage name='resume' component='div' className='text-red-500 text-sm' />
                </div>
              </div>

              <div className='py-2 px-28 inline-flex justify-center items-center border border-white hover:scale-105 transition-all duration-500 cursor-pointer !mt-14'>
                <button type='submit' className='text-[10px] font-bold text-white' disabled={isSubmitting}>
                  GÖNDER
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <a href='https://www.eleman.net/firma/inceoglu-makina-ticaret-ve-sanayi-a-s-f1771165' target='_blank' className='h-full w-full max-w-screen-md flex flex-col justify-center space-y-5 mt-20 border-inceoglunYellow1 border px-5 py-5 mb-20 cursor-pointer hover:scale-105 transition-all duration-300'>
        <div className='flex justify-between h-20 '>
          <div className='flex items-center space-x-5'>
            <img src='/images/logos/logo.png' className='w-16 md:w-44' />
            <div className='text-white'>Açık iş ilanlarına göz atmak için tıklayınız.</div>
          </div>
          <div >
            <img src='/images/kariyer-arrow.svg' className='h-full'/>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Isilanlari;
