import React from 'react'

const ProfesyonellerinYaninda = () => {
  return (
    <div>

      <div className='w-full  bg-black space-y-16 6 py-20 md:py-28 '>
        {/* <div className='h-full w-full flex flex-col justify-center  space-y-20 pt-20'>
        <h3 className='text-white text-4xl text-center Anton'>Araştırma Ve Geliştirme</h3>
        <h3 className='text-white text-2xl text-center Anton'>Doğaya Uygulanan Teknoloji</h3>
        <div>
          <h6 className='text-gray-300 text-center Oswald' >Reolojik kalite, plastisite ve fermantasyon özellikleri ve mikrobiyolojik parametrelere uygunluk:</h6>
          <h6 className='text-gray-300 text-center Oswald' >

            Fırıncılık profesyonelleri için güvenilir ve teknik açıdan performanslı bir ürün için Molino Pordenone, yüzyılı aşkın süredir edinilen zengin bir uzmanlığa sahiptir. Bu zengin deneyim sayesinde, mevsimlere ve hasatlara göre özellikleri değişen canlı bir malzeme olan buğdayın değişkenliğini yönetebiliyor ve tutarlı kalitede bir ürün garanti edebiliyoruz.
          </h6>
        </div>
      </div> */}

        <div className='w-full  overflow-hidden relative pt-8 md:pt-0 '>
          <img
            src="/images/general/d.jpg "
            alt="logo"
            className=" w-full h-full object-center object-cover  absolute opacity-60 "
          />

          <div className=' flex flex-col top-0 left-0  px-[10%]  py-20 relative z-10   md:min-h-screen'>
            <h1 className='montSerrat text-white font-bold text-5xl mb-2'> Değirmencilik Tarihi​</h1>
            <h2 className='montSerrat text-white text-2xl'>Seksen yıllık köklü bir değirmencilik tarihi​</h2>



          </div>
        </div>






        <div className='w-full  overflow-hidden relative pt-8 md:pt-0 '>
          <img
            src="/images/general/z.jpg"
            alt="logo"
            className=" w-full h-full object-center object-cover  absolute opacity-60 "
          />

          <div className=' flex flex-col top-0 left-0  px-[10%]  py-20 relative z-10   md:min-h-screen'>
            <h1 className='montSerrat text-white font-bold text-5xl mb-2'> Ürün Geliştirme​​</h1>
            <h2 className='montSerrat text-white text-2xl'>Taleplere yönelik özel unlar üretiyoruz​​</h2>



          </div>
        </div>





        <div className='w-full  overflow-hidden relative pt-8 md:pt-0 '>
          <img
            src="/images/general/inceoglun-build.jpg"
            alt="logo"
            className=" w-full h-full object-center object-cover  absolute opacity-60 "
          />

          <div className=' flex flex-col top-0 left-0  px-[10%]  py-20 relative z-10   md:min-h-screen'>
            <h1 className='montSerrat text-white font-bold text-5xl mb-2'> Size Özel​​</h1>
            <h2 className='montSerrat text-white text-2xl'>İstediğiniz ürüne ulaşmak sadece bir tık uzağınızda​​​</h2>



          </div>
        </div>













































      </div>

    </div>
  )
}

export default ProfesyonellerinYaninda