import React from 'react'
import { motion } from "framer-motion"
const VizyonMisyon = () => {

  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className='min-h-screen  w-full px-8 md:px-16 lg:px-36 py-20 md:py-28 ' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>
      <div className='grid lg:grid-cols-2 grid-cols-1 h-full w-full content-center pt-20 space-y-20 lg:space-y-0'>
        <div className='h-full w-full  space-y-10 pr-20'>
          <motion.div variants={itemText} className='text-white space-y-4'>

            <h5 className='text-4xl font-bold '>Misyon</h5>
            <div className='space-y-2 text-slate-300'>
              <p className='text-base '>Müşterilerimizin özel proseslerine uygun ürünleri uluslararası gıda standartlarına uygun olacak şekilde ve sürdürülebilir bir kalite altında tedarik etmek.

              </p>
              <p> Güvenilir bir iş ortağı olmak.</p>
            </div>
          </motion.div>
          <motion.div variants={itemText} className='text-white  space-y-4'>
            <h5 className='text-4xl font-bold'>Vizyon</h5>
            <div className='space-y-2 text-slate-300'>
              <p className='text-base'>Markamızla tanışan profesyonellerin tüm taleplerine karşılık verebilmek ve sürdürülebilir bir memnuniyet zinciri kurabilmek.
              </p>

              <p>Tüketicilerimizle buluştuğumuz tüm sofralarda insanları mutlu etmek.</p>

              <p> Tarladan sofraya sürdürülebilir bir değer zinciri yaratmak.</p>
            </div>
          </motion.div>
        </div>
        <motion.div variants={itemText} className='h-full w-full '>
          <img src='/images/about/vision.png' />
        </motion.div>
      </div>
    </motion.div>
  )
}

export default VizyonMisyon