import React from 'react'
import { motion } from 'framer-motion';


const Slider = () => {
  const words1 = '78 YILLIK'.split(' ');
  const words2 = 'BİR EMEK YOLCULUĞU'.split(' ');

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.15, delayChildren: 0.04 * i },
    }),
  };

  const child = {
    hidden: { y: '100%', opacity: 0 },
    visible: {
      y: '0%',
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 70,
        stiffness: 200,
     
      },
    },
  };


  return (
    <div class="xSlider !overflow-x-hidden"  >

      <section class="panel  " className="bg-black w-full md:h-[calc(100vh)] h-[calc(100vh)]  panel  relative overflow-hidden" >
        <video autoPlay muted loop className='h-full w-full object-cover'>
          <source src="/videos/4.mp4" type="video/mp4" className='h-full w-full' />

        </video>
        <div className="absolute h-full w-full top-0 left-0 bg-[#924a2c] mix-blend-overlay"></div>

        <div className='absolute h-full w-full top-0 left-0 py-20 px-8 md:px-16 lg:px-36 flex items-end'>
      <div className='max-w-3xl py-10 space-y-5'>
        <motion.div
          className='text-white  flex overflow-hidden'
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {words1.map((word, index) => (
            <motion.span
              key={index}
              variants={child}
              className='mr-2 text-3xl md:!text-5xl '
            >
              {word}
            </motion.span>
          ))}
        </motion.div>

        <motion.div
          className='text-white text-5xl flex overflow-hidden'
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {words2.map((word, index) => (
            <motion.span
              key={index}
              variants={child}
              className='mr-2 text-3xl md:!text-5xl'
            >
              {word}
            </motion.span>
          ))}
        </motion.div>
      </div>
    </div>



      </section>
      <section class="panel orange" className="bg-black w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-7rem)] md:mt-[7rem] mt-[7rem] panel  relative overflow-hidden">
        <video autoPlay muted loop className='h-full w-full object-cover'>
          <source src="/videos/5.mp4" type="video/mp4" className='h-full w-full' />

        </video>


        <div className="absolute h-full w-full top-0 left-0 bg-[#5f4589] mix-blend-overlay "></div>
        <div className='absolute h-full w-full  top-0 left-0 py-20 px-8 md:px-16 lg:px-36 flex items-end'>

          <div className=' max-w-3xl  py-10 space-y-5 '>
            <div className='text-white text-3xl md:!text-5xl'>MARKALAŞMA</div>
            <div className='text-white text-3xl md:!text-5xl'>ÜRÜN ÇEŞİTLİLİĞİ</div>
          </div>



        </div>







      </section>
      {/* <section class="panel purple" className="bg-black w-full md:h-[calc(100vh-7rem)] h-[calc(100vh-10rem)] md:mt-[7rem] mt-[5rem] panel overflow-hidden">
        <img
          src="images/slider/slider3.jpg"
          className="w-full object-cover h-full"
          alt="slider-3"
        />

      </section> */}




    </div>
  )
}

export default Slider