import React from 'react'
import { motion } from "framer-motion"


const certificateData = [
    {
        logo: 'LOGO',
        text: ' Helal Sertifikasyonu Gimdes Helal Gıda ve Sertifikalama Araştırma Derneği',
        link: '/pdf/kalite-yonetimi/BRC-Certificate.pdf',
        image: '/images/kalite-yonetimi/BRCGS_CERT_FOOD_LOGO.png',
        title1: 'BRC Issue 9',
        title2: 'İngiliz Parekendecilik Konsorsiyumu Uluslararası Gıda Güvenliği Standardı',
    },
    {
        logo: 'LOGO',
        text: ' Helal Sertifikasyonu Gimdes Helal Gıda ve Sertifikalama Araştırma Derneği',
        link: '/pdf/kalite-yonetimi/gimdes-halal.jpeg',
        image: '/images/kalite-yonetimi/Gimdes-HALAL.svg',
        title1: 'Helal Sertifikasyonu',
        title2: 'Gimdes Helal Gıda ve Sertifikalama Araştırma Derneği',

    },
    {
        logo: 'LOGO',
        text: ' Helal Sertifikasyonu Gimdes Helal Gıda ve Sertifikalama Araştırma Derneği',
        link: '/pdf/kalite-yonetimi/Inceoglu-Makine-Ticaret-ve-Sanayi-LOC-2024.pdf',
        image: '/images/kalite-yonetimi/starkey.svg',
        title1: 'Star K Kosher',
        title2: 'STAR-K kosher sertifikası',

    },





]

const GidaYonetimiSistemi = () => {


    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2,
            },
        },
        hidden: { opacity: 0 },

    }

    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }



    return (
        <motion.div initial="hidden" whileInView="visible" variants={list} className='min-h-screen   w-full px-8 md:px-16 lg:px-36 pt-20 md:pt-28 ' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>
            <div className='h-full w-full flex flex-col justify-center space-y-20 pt-20'>
                <motion.div variants={itemText} className='text-white space-y-4 '>

                    <h5 className='text-4xl font-bold '>Gıda Güvenliği Sistemi</h5>
                    <div className='space-y-2 text-gray-300'>
                        <p>Müşterilerimizin bize olan güveninin, sunduğumuz ürün ve hizmetlerin kalitesine bağlı olduğuna inanıyoruz. Bu nedenle iyileştirme arayışımızı sürdürüp, en mükemmel sonucu yakalamayaçalışıyoruz.</p>
                        <p>Gıda üretiminin birinci kuralı hijyendir. Bu nedenle gıda güvenliğini sağlamak için uluslararası hijyen esaslarını işletmemizde uyguluyoruz. Kaliteli ürün için kaliteli ham madde gereksinimi
                            dolayısıyla konusunda uzman tedarikçilerle çalışıyoruz.
                        </p>
                        <p>Firmamız bünyesinde kalite kontrolden sorumlu gıda mühendislerimizin denetimleriyle üretim prosesi ve ürün kalitesi sürekli kontrol altındadır.
                            Müşterilerimizin öneri ve isteklerini son derece önemsiyoruz. Bu talepleri, mühendislerimizle büyük bir hassasiyetle değerlendirip, gerektiğinde Ar-Ge projeleri kapsamında değirmen ve fırınımızda
                            veya müşterilerimizin kendi üretim tesislerinde birlikte çalışmalar yapıyoruz.
                        </p>
                        <p>Üretim teknolojilerimizin  modernizasyonunu, uluslararası standartları devamlı takip ederek ve son yeniliklere göre güncelleyerek sağlıyoruz.
                            Sektörümüzdeki başarımızı kalitemize borçlu olduğumuz bilinci ile, bu yöndeki anlayışımızdan taviz vermeden çalışmalarımıza devam ediyoruz.</p>
                    </div>
                </motion.div>



                <div className='grid grid-cols-2 lg:grid-cols-5 gap-[0.1rem] !mb-40'>

                    {
                        certificateData.map((item, index) => {
                            return (

                                <motion.div key={index} variants={itemText} >
                                    <div className='h-full w-full  group hover:scale-105 transition-transform  duration-500 overflow-hidden cursor-pointer'>
                                        <div className=' h-full  flex justify-center items-center font-bold group-hover:h-full group-hover:p-0 transition-all duration-500'>
                                            <a href={item.link} target='_blank' className='w-full bg-white h-full flex flex-col justify-center items-center'>

                                                <img className='  w-36 py-5' src={item.image} />
                                                <div className='bg-inceoglunYellow1  p-3 h-full w-full'>
                                                    <h5 className='text-white text-lg mb-2 text-center'>{item.title1}</h5>
                                                    <h5 className=' text-white text-sm text-center font-medium'>{item.title2}</h5>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </motion.div>

                            )
                        })
                    }






                </div>
            </div>


        </motion.div>
    )
}

export default GidaYonetimiSistemi