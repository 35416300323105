import React from 'react'

const Section6 = () => {
  return (
    <div className=" bg-black w-full  pt-32 pb-16 flex  px-8 md:px-16 lg:px-32 items-center justify-center">


    <div className=" grid grid-cols-1  md:grid-cols-7  gap-5 md:gap-10">
      <div className="  flex flex-col items-center space-y-2 ">
        <h5 className="text-4xl font-bold  text-white animCounter">4</h5>
        <h5 className="text-1xl md:text-2xl  font-bold text-white">
          Jenerasyon
        </h5>
        <h5 className="text-1xl  text-white">
          Değirmenci
        </h5>
      </div>

      <div className="  flex flex-col items-center h-full ">
        <div className=" w-full h-full hidden md:flex  items-center">
          <div className="h-px w-full bg-white  "></div>
        </div>
        <h5 className="text-[0.4rem]  md:text-sm md:block">Jenerasyon</h5>
        <div className="w-1 h-20 flex justify-center md:hidden">
          <div className="h-full w-px bg-white"></div>
        </div>
      </div>

      <div className="  flex flex-col items-center space-y-2 ">
        <h5 className="text-4xl  font-bold  text-white animCounter">80</h5>
        <h5 className="text-1xl md:text-2xl  font-bold text-white">
          Yıl
        </h5>
        <h5 className="text-1xl  text-white">
          Sektör Tecrübesi
        </h5>
      </div>

      <div className="  flex flex-col items-center h-full ">
        <div className=" w-full h-full hidden md:flex  items-center">
          <div className="h-px w-full bg-white  "></div>
        </div>
        <h5 className="text-[0.4rem]  md:text-sm hidden md:block">Yıl</h5>
        <div className="w-1 h-20 flex justify-center md:hidden">
          <div className="h-full w-px bg-white"></div>
        </div>
      </div>

      <div className="  flex flex-col items-center space-y-2 ">
        <h5 className="text-4xl font-bold  text-white animCounter">100000</h5>
        <h5 className="text-1xl md:text-2xl  font-bold  text-white">
          Ton
        </h5>
        <h5 className="text-1xl  text-white">
          Yıllık Kapasite
        </h5>
      </div>

      <div className="  flex flex-col items-center h-full ">
        <div className=" w-full h-full hidden md:flex  items-center">
          <div className="h-px w-full bg-white  "></div>
        </div>
        <h5 className="text-[0.4rem]  md:text-sm hidden md:block">Ton</h5>
        <div className="w-1 h-20 flex justify-center md:hidden">
          <div className="h-full w-px bg-white"></div>
        </div>
      </div>


      <div className="  flex flex-col items-center space-y-2 ">
        <h5 className="text-4xl  font-bold  text-white animCounter">100</h5>
        <h5 className="text-1xl md:text-2xl  font-bold  text-white">
          %
        </h5>
        <h5 className="text-1xl  text-white">
          İstikrarlı Kalite
        </h5>
      </div>




    </div>

  </div>
  )
}

export default Section6