import React from 'react'
import { motion, useScroll } from "framer-motion"
import { Link } from 'react-router-dom'



const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1 ,transition:{duration:1}}, hidden: { opacity: 0 } }




const Section1 = () => {
    return (
        <motion.div initial="hidden" whileInView="visible" variants={list} className=" my-20 w-full  space-y-10  md:space-y-0 grid grid-cols-1 md:grid-cols-3  px-8 md:px-16 lg:px-36 py-12 md:gap-10 3xl:container mx-auto">
            <motion.div variants={itemText} className=" grid-cols-3 space-y-5  ">
                <h6 className="text-3xl text-white font-bold">
                MİSYON & VİZYON</h6>
                <p className="text-white yourElement">
                Misyonumuz : Kaliteli un ve un türevi ürünlerin üretimini sağlayarak insanların güvenli gıdaya ulaşmasını sağlamaktır.
                </p>
                <p className="text-white">
                Vizyonumuz : "Gıdaya saygı, çevreye duyarlı, uluslararası bir gıda tedarikçisi olmak"
                </p>
                <Link  to={'kurumsal/vizyon-misyon'}>
                <div className="h-10 w-[173px]  flex justify-center items-center  border border-white mt-5 hover:scale-105 transition-all duration-500">
                    <h6 className="text-[10px] font-bold text-white">
                        DAHA FAZLA İÇİN
                    </h6>
                </div>
                </Link>
            </motion.div>
            <motion.div variants={itemText} className=" grid-cols-3 space-y-5  ">
                <h6 className="text-3xl text-white font-bold">AR-GE</h6>
                <p className="text-white yourElement">
                Fabrikamızda  bulunan  deneme  fırınımızda;  günlük  üretimden  ve  sevk  edilen  unlardan  alınan  numuneler ekmek  yapımında uzman gıda  mühendislerimiz  tarafından ekmek üretimine tabi tutulur. Böylece  unun  son  üründeki  kalitesi  gözlemlenir ve analizi yapılır.
                </p>

                <Link  to={'inovasyon/innovasyon-merkezi'}>
                <div className="h-10 w-[173px]  flex justify-center items-center  border border-white mt-5 hover:scale-105 transition-all duration-500">
                    <h6 className="text-[10px] font-bold text-white ">
                        DAHA FAZLA İÇİN
                    </h6>
                </div>
                </Link>
            </motion.div>
            <motion.div variants={itemText} className=" grid-cols-3 space-y-5  ">
                <h6 className="text-3xl text-white font-bold">KALİTE POLİTİKAMIZ
                </h6>
                <p className="text-white yourElement">
                Müşterilerimizin bize olan güveninin, sunduğumuz ürün ve hizmetlerin kalitesine bağlı olduğuna inanıyoruz. Bu nedenle  iyileştirme arayışımızı sürdürüp , en mükemmel sonucu yakalamaya çalışıyoruz.
                </p>
    
                <Link  to={'kurumsal/kalite-yonetimi'}>
                <div className="h-10 w-[173px]  flex justify-center items-center  border border-white mt-5 hover:scale-105 transition-all duration-500">
                    <h6 className="text-[10px] font-bold text-white">
                        DAHA FAZLA İÇİN
                    </h6>
                </div>
                </Link>
            </motion.div>
        </motion.div>
    )
}

export default Section1