import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

import { DATA } from '../data/data-urunler2'






const AllProducts = () => {


    const list = {
        visible: {
            opacity: 1,
    
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2,
            },
        },
        hidden: { opacity: 0 },
    
    }
    
    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
    return (
        <motion.div initial="hidden" whileInView="visible" variants={list} className=' py-20'>
            <motion.h5 variants={itemText} class="text-4xl font-bold  text-white mb-20">Tüm Ürünler</motion.h5>

            <div className='grid grid-cols-3 md:grid-cols-6 gap-10 !mb-40'>

{
    DATA.map((product,index)=>{
        return(
            product.products.map((item,index)=>{
                return(
                    <motion.div key={index} variants={itemText} >
                                    <Link to={`/urunler/${product.url}/${item.url}`}>
                                    <img src={item.image} className='hover:scale-105 transition-all duration-500'/>
                                    </Link>
                            </motion.div>
                )
            })
        )
    })
    // DATA.products.map((item, index) => {
    //     return (

    //         <motion.div key={index} variants={itemText} >
    //                 <Link to={item.link}>
    //                 <img src={item.image} className='hover:scale-105 transition-all duration-500'/>
    //                 </Link>
    //         </motion.div>

    //     )
    // })
}






</div>


        </motion.div>
    )
}

export default AllProducts