import React from 'react'

const IM = () => {
  return (
    <div>

      <div className='w-full  bg-black space-y-16 6 py-20 md:py-28 '>
        {/* <div className='h-full w-full flex flex-col justify-center  space-y-20 pt-20'>
        <h3 className='text-white text-4xl text-center Anton'>Araştırma Ve Geliştirme</h3>
        <h3 className='text-white text-2xl text-center Anton'>Doğaya Uygulanan Teknoloji</h3>
        <div>
          <h6 className='text-gray-300 text-center Oswald' >Reolojik kalite, plastisite ve fermantasyon özellikleri ve mikrobiyolojik parametrelere uygunluk:</h6>
          <h6 className='text-gray-300 text-center Oswald' >

            Fırıncılık profesyonelleri için güvenilir ve teknik açıdan performanslı bir ürün için Molino Pordenone, yüzyılı aşkın süredir edinilen zengin bir uzmanlığa sahiptir. Bu zengin deneyim sayesinde, mevsimlere ve hasatlara göre özellikleri değişen canlı bir malzeme olan buğdayın değişkenliğini yönetebiliyor ve tutarlı kalitede bir ürün garanti edebiliyoruz.
          </h6>
        </div>
      </div> */}

        <div className='w-full  overflow-hidden relative pt-8 md:pt-0 '>
          <img
            src="/images/general/1.jpg"
            alt="logo"
            className=" w-full h-full object-center object-cover  absolute "
          />

          <div className=' flex flex-col top-0 left-0  px-[10%]  py-20 relative z-10   md:min-h-screen'>
            <h1 className='montSerrat text-white font-bold text-5xl mb-2'>Son Teknoloji Laboratuvar </h1>
            <h2 className='montSerrat text-white text-2xl'>Her gün yüzlerce analiz yapılan fabrikamızın merkezi </h2>



          </div>
        </div>


        <div className='w-full flex flex-col space-y-14 px-8  md:px-40 lg:px-40'>


          <div className='w-full'>
            <h6 className='text-gray-300 font-light ' >
              İnnovasyon kelimesinin etimolojik kökeni Latince ‘‘novātiō’’ kelimesine dayanır. Novātiō; yenilik yapma, yenileme veya var olanda değişiklikler yapma anlamlarına gelmektedir. İnceoğlu Değirmeni olarak innovasyon ve ar-ge faaliyetlerinin gücüne inanıyoruz. Mevcut ürünlerimizde yenilikler ve  geliştirmeler yapmak adına laboratuvarlarımızda her daim araştırmalar yapıyor, Fırıncılık Deneme Merkezimiz olan 450 °F ‘da bu araştırmaların sonuçlarını pekiştiriyoruz. Mevcut müşterilerimizin proseslerindeki değişimleri sorguluyor, ürünlerimizi gerektiği takdirde onlara özel olarak adapte ediyor, öte yandan potansiyel müşterilerimizin taleplerine uygun ürünleri onlar için geliştiriyoruz. Bu bağlamda müşteriye özel buğday paçalları, premiks denemeleri, katkı çalışmaları ve un paçallarında yapılan varyasyonlar, en uygun proses çözümünün ortaya çıkarılmasında destekleyici oluyor.</h6>
          </div>
        </div>

        <div className='w-full  overflow-hidden relative pt-8 md:pt-0 '>
          <img
            src="/images/general/2.jpg"
            alt="logo"
            className=" w-full h-full object-center object-cover  absolute "
          />

          <div className=' flex flex-col top-0 left-0  px-[10%]  py-20 relative z-10   md:min-h-screen'>
            <h1 className='montSerrat text-white font-bold text-5xl mb-2'>Kalite Ekibi  </h1>
            <h2 className='montSerrat text-white text-2xl'>Tecrübeli, donanımlı ve yeniliğe açık uzman bir kadro</h2>



          </div>
        </div>

        <div className='w-full flex flex-col  space-y-14 px-8  md:px-40 lg:px-40'>


          <div className='text-gray-300 flex flex-col w-full font-light items-center'>
            <p className='mb-12'>Reolojik Analiz ve Yorumlarımızı iki ayrı paralelde
              değerlendiriyoruz.</p>
            <p className='mb-6'>Hamurun Gaz Tutma ve Gaz Üretme Karakteristiği
            </p>
            <p className='mb-6'><span className='font-semibold'>Gaz Tutma</span> karakteristiği, protein ve gluten yapısıyla
              alakalı bir karakteristiktir. Bununla alakalı
              laboratuvarımızda Inframatic, Gluten Indeks,
              Sedimantasyon, Extensograph & Farinograph cihazları
              vasıtasıyla protein, gluten miktar ve kalitesi hakkında
              sonuçlara ulaşıyoruz.</p>
            <p><span className='font-semibold'>Gaz Üretme</span> karakteristiği ise enzimatik değerlerin
              ölçümlenmesi ile ortaya çıkmaktadır. Bu bağlamda
              Falling Number ve Amilograph cihazlarımızla numerik
              sonuçlara ulaşıyoruz.</p>
          </div>
        </div>


        <div className='w-full  overflow-hidden relative pt-8 md:pt-0 '>
          <img
            src="/images/general/4.jpg"
            alt="logo"
            className=" w-full h-full object-center object-cover  absolute "
          />

          <div className=' flex flex-col top-0 left-0  px-[10%]  py-20 relative z-10   md:min-h-screen'>
            <h1 className='montSerrat text-white font-bold text-5xl mb-2'>Paçal Mühendisliği</h1>
            <h2 className='montSerrat text-white text-2xl'>Her bir tanesini tanıyor, siz müşterilerimize özel paçal yapıyoruz </h2>



          </div>
        </div>



        <div className='w-full flex flex-col  space-y-14 px-8  md:px-40 lg:px-40'>


          <div className='text-gray-300 flex flex-col w-full  font-light'>
            <p className='mb-6'>Ürünlerimizi reolojik kalite ve mikrobiyolojik uygunluk
              açısından analiz ediyoruz. Bununla beraber üçüncü parti
              olarak akredite laboratuvarlara numuneler gönderiyoruz.</p>
            <p className='mb-6'>Birincil önceliğimiz her zaman gıda güvenliği olmakla
              beraber ürün kalitesini kusursuz kılmaya özen
              gösteriyoruz</p>
            <p className='mb-6'>Fırıncılık alanındaki ve hizmet ettiğimiz tüm alanlardaki
              profesyoneller için güvenilir ve performanslı ürünleri
              seksen yılı aşkın bir tecrübe ile üretiyoruz. Sahip
              olduğumuz bu köklü deneyim, canlı bir malzeme olan
              buğdayın değişkenliklerini yönetebilmemize ve tutarlı
              ürünler ortaya çıkarmamıza vesile olmaktadır.</p>
          </div>
        </div>















      </div>

    </div>
  )
}

export default IM