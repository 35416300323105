import React, { useEffect, useLayoutEffect, useState } from 'react'

import { DATA } from '../data/data-urunler2'
import { Link, useLocation, useParams } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import CircularProgress from '../components/CircularProgress'


import { delay, motion } from "framer-motion"
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax'
import CountUp from 'react-countup';
import AllProducts from '../components/AllProducts'


const Urunler = ({ setHeaderTransparent }) => {




  const location = useLocation()


  const splitedLocation = location.pathname.split('/')
  const path1 = splitedLocation[2]
  const path2 = splitedLocation[3]


  const getMenu = DATA.map((item) => (
    path1 !== item.url ? { name: item.name, url: item.url, icon: item.icon } : ''

  )).filter((e) => e.name)


  const filteredData = DATA.filter((item) => item.url === path1)[0]


  const filteredDataInner = filteredData && filteredData.products.filter((item) => item.url === path2)[0]

  console.log(filteredDataInner)


  useLayoutEffect(() => {
    setHeaderTransparent(true)

    return (() => setHeaderTransparent(false))


  }, [])





  const variants = {
    visible: index => ({
      opacity: 1,

      transition: {
        duration: (index + 1) * 2,
        delay: (index + 1) * 0.3,
      },
    }),
    hidden: { opacity: 0 },
  }

  const variants2 = {
    visible: index => ({
      opacity: 1,

      transition: {
        duration: (index + 1) * 2,

      },
    }),
    hidden: { opacity: 0 },
  }


  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }


  const itemWidth = { visible: value => ({ width: value, transition: { duration: 1 } }), hidden: { width: '0%' }, }



  return (
    <div className='px-8 md:px-16 lg:px-36 pt-28     ' style={{ background: "rgb(69,40,0)", background: "radial-gradient(circle, rgba(69,40,0,1) 0%, rgba(0,0,0,1) 100%)" }}>


      {
        ((filteredData && filteredData.length !== 0)) ?
          <div className='min-h-screen pb-20'>
            <motion.h5 animate={{ opacity: 1, transition: { duration: 1 } }} initial={{ opacity: 0 }} className='text-white mt-10 mb-20'>{`Ürünler/${path1}${path2 ? '/' + path2 : ' '}`}</motion.h5>
            <div className="grid grid-cols-12 gap-4 ">

              <div className="col-span-12 lg:col-span-2   flex lg:flex-col  flex-row-reverse justify-end lg:justify-start">
                <motion.div animate={{ translateY: 0, transition: { duration: 1 } }} initial={{ translateY: -10 }} className=''>
                  <Link to={filteredData.url} className='flex items-center space-x-2 mb-2' >
                    <img className='h-6 w-6' src={filteredData.icon} />
                    <h5 className='text-base lg:text-2xl  cursor-pointer hover:scale-105 transition-transform text-white'>{filteredData.name}</h5>
                  </Link>

                  {
                    filteredData.products.map((item, index) => {
                      return (
                        <Link key={index} to={`${filteredData.url}/${item.url}`} ><motion.h5 custom={index} animate="visible" initial="hidden" variants={variants2} className='text-xs lg:text-sm text-white cursor-pointer hover:scale-105 transition-transform  opacity-60 scale-100 mb-1 hover:text-inceoglunYellow1 }'>{item.title_2}</motion.h5></Link>
                      )
                    })
                  }

                </motion.div>
                <motion.div className='mt-0 lg:mt-10 mr-10 lg:mr-0'>

                  {getMenu.map((item, index) => {
                    return (

                      <Link key={index} to={item.url} >
                        <motion.div custom={index} animate="visible" initial="hidden" variants={variants2} className='flex items-center space-x-2 space-y-0 lg:space-y-2 group'>
                          <img className='h-6 w-6' src={item.icon} />

                          <h5 className="text-base lg:text-2xl text-gray-400 cursor-pointer hover:scale-105 transition-transform group-hover:text-inceoglunYellow1">{item.name}</h5>
                        </motion.div>
                      </Link>
                    )
                  })}
                </motion.div>



              </div>
              {((filteredDataInner && filteredDataInner.length !== 0)) ?
                <>
                  <div className="col-span-12 lg:col-span-4 lg:col-start-3 lg:pr-24">

                    <motion.img animate={{ opacity: 1, transition: { duration: 1 } }} initial={{ opacity: 0 }} src={filteredDataInner.image} />



                  </div>
                  <div className="lg:col-span-6 lg:col-start-7 space-y-5 lg:space-y-10 col-span-12">
                    <div className='flex  space-x-10'>
                      <TypeAnimation
                        sequence={[

                          `${filteredDataInner.title_1}`,
                          1000

                        ]}
                        cursor={false}
                        speed={1}
                        className='text-4xl font-extrabold leading-none text-white animate-changeOpacity1'
                        

                      />

                      <TypeAnimation
                        sequence={[

                          `${filteredDataInner.title_2}`,
                          1000,

                        ]}
                        cursor={false}
                        speed={1}
                        className='text-4xl  font-semibold  leading-none text-white animate-changeOpacity1 border-l-2 pl-2'
            

                      />
                    </div>

                    <div className="text-2xl text-gray-300 cubaFamilly">Ürün Özellikleri</div>
                    <p className="text-gray-300 text-xl font-light tracking-widest">{filteredDataInner.productText}
                    </p>

                    <motion.div initial="hidden" whileInView="visible" variants={list} className='w-full flex flex-col items-start  space-y-5 '>

                      <div className=' w-full grid grid-cols-2 lg:grid-cols-3 gap-10'>
                        <motion.div variants={itemText} className='flex flex-col space-y-2  '>
                          <div className='flex space-x-2 text-white text-sm items-center'>
                            <h5 className='text-xl'>
                              <CountUp end={filteredDataInner.ibre.protein} decimals={2} decimal="," duration={2} className='' />
                            </h5>
                            <h5>PROTEIN</h5>
                          </div>
                          <div className='h-1 w-full bg-black relative'>
                            <motion.div animate={{ width: `${filteredDataInner.ibre.protein * 100 / 12}%`, transition: { duration: 2  } }} initial={{ width: '%0' }} className={`absolute top-0 left-0 h-1  bg-inceoglunYellow1 `}></motion.div>
                          </div>
                        </motion.div>
                        <motion.div variants={itemText} className='flex flex-col space-y-2  '>
                          <div className='flex space-x-2 text-white text-sm items-center'>
                            <h5 className='text-xl'>
                              <CountUp end={filteredDataInner.ibre.kül} decimals={2} decimal="," duration={2} className='' />
                            </h5>
                            <h5>KÜL%</h5>

                          </div>
                          <div className='h-1 w-full bg-black relative'>
                            <motion.div animate={{ width: `${filteredDataInner.ibre.kül * 100 / 2}%`, transition: { duration: 2} }} initial={{ width: '%0' }} className={`absolute top-0 left-0 h-1  bg-inceoglunYellow1 `}></motion.div>
                          </div>
                        </motion.div>
                        <motion.div variants={itemText} className='flex flex-col space-y-2  '>
                          <div className='flex space-x-2 text-white text-sm items-center'>
                            <h5 className='text-xl'>
                              <CountUp end={filteredDataInner.ibre.energy} decimals={2} decimal="," duration={2} className='' />
                            </h5>
                            <h5>ENERJİ</h5>

                          </div>
                          <div className='h-1 w-full bg-black relative'>
                            <motion.div animate={{ width: `${filteredDataInner.ibre.energy * 100 / 300}%`, transition: { duration: 2 } }} initial={{ width: '%0' }} className={`absolute top-0 left-0 h-1  bg-inceoglunYellow1 `}></motion.div>
                          </div>
                        </motion.div>
                        <motion.div variants={itemText} className='flex flex-col space-y-2  '>
                          <div className='flex space-x-2 text-white text-sm items-center'>
                            <h5 className='text-xl'>
                              <CountUp end={filteredDataInner.ibre.gluten} decimals={2} decimal="," duration={2} className='' />
                            </h5>
                            <h5>GLUTEN</h5>

                          </div>
                          <div className='h-1 w-full bg-black relative'>
                            <motion.div animate={{ width: `${filteredDataInner.ibre.gluten * 100 / 35}%`, transition: { duration: 2 } }} initial={{ width: '%0' }} className={`absolute top-0 left-0 h-1  bg-inceoglunYellow1 `}></motion.div>
                          </div>
                        </motion.div>
                        <motion.div variants={itemText} className='flex flex-col space-y-2  '>
                          <div className='flex space-x-2 text-white text-sm items-center'>
                            <h5 className='text-xl'>
                              <CountUp end={filteredDataInner.ibre.sed} decimals={2} decimal="," duration={2} className='' />
                            </h5>
                            <h5>SEDİMANTASYON</h5>

                          </div>
                          <div className='h-1 w-full bg-black relative'>
                            <motion.div animate={{ width: `${filteredDataInner.ibre.sed * 100 / 65}%`, transition: { duration: 2 } }} initial={{ width: '%0' }} className={`absolute top-0 left-0 h-1  bg-inceoglunYellow1 `}></motion.div>
                          </div>
                        </motion.div>
                        {
                          filteredDataInner.ibre.stab &&
                          <motion.div variants={itemText} className='flex flex-col space-y-2  '>
                            <div className='flex space-x-2 text-white text-sm items-center'>
                              <h5 className='text-xl'>
                                <CountUp end={filteredDataInner.ibre.stab} decimals={2} decimal="," duration={2} className='' />
                              </h5>
                              <h5>STABİLİTE+</h5>

                            </div>
                            <div className='h-1 w-full bg-black relative'>
                              <motion.div animate={{ width: `${filteredDataInner.ibre.stab * 100 / 18}%`, transition: { duration: 2 } }} initial={{ width: '%0' }} className={`absolute top-0 left-0 h-1  bg-inceoglunYellow1 `}></motion.div>
                            </div>
                          </motion.div>
                        }
                      </div>
                      {/* <div className='flex  w-full justify-start  animate-changeOpacity1 '>
                        <CircularProgress proportionedProgressPercentage={filteredDataInner.ibre.protein * 100 / 12} progressPercentage={filteredDataInner.ibre.protein} name={'PROTEIN'} />
                        <CircularProgress proportionedProgressPercentage={filteredDataInner.ibre.kül * 100 / 2} progressPercentage={filteredDataInner.ibre.kül} name={'KÜL %'} />
                        <CircularProgress proportionedProgressPercentage={filteredDataInner.ibre.energy * 100 / 300} progressPercentage={filteredDataInner.ibre.energy} name={'ENERJİ'} />
                      </div>

                      <div className='flex  w-full justify-start  animate-changeOpacity1'>
                        <CircularProgress proportionedProgressPercentage={filteredDataInner.ibre.gluten * 100 / 35} progressPercentage={filteredDataInner.ibre.gluten} name={'GLUTEN'} />
                        <CircularProgress proportionedProgressPercentage={filteredDataInner.ibre.sed * 100 / 65} progressPercentage={filteredDataInner.ibre.sed} name={'SEDİMANTASYON'} />
                        {
                          filteredDataInner.ibre.stab && (<CircularProgress operator={'+'} proportionedProgressPercentage={filteredDataInner.ibre.protein * 100 / 18} progressPercentage={filteredDataInner.ibre.stab} name={'STABİLİTE'} />)
                        }

                      </div> */}

                    </motion.div>

                      <p className="text-gray-300 text-lg">Teslim Şekilleri</p>
                      <div className="flex ">
                        {
                          filteredDataInner.teslimSekilleri.map((item,index)=>{
                            return(
                              <img className='w-36' src={`/images/teslimsekilleri/${item}.png`}/>
                            )
                          })
                        }

                      </div>

                      <p className="text-gray-300 text-lg">Kullanım Alanları</p>
                      <p className="text-gray-300 text-base">{filteredDataInner.kullanimAlanlari}</p>
                      <p className="text-gray-300 text-lg">Ürün Grubu</p>
                      <p className="text-gray-300 text-base">{filteredDataInner.urunGrubu}</p>
                        <a href={filteredDataInner.pdf}>
                      <div class="h-16 w-full flex justify-end"><img src="/images/products/pdf.svg" class="w-16 lg:w-auto animate-contentIcons4 hover:scale-105 duration-300" alt="pdf"/></div>
                      </a>


                      <p className='text-white text-xs'>Teslim şekilleri ambalaj stok durumuna göre değişkenlik gösterebilir. Ürünlerin Enerji, Gluten ve Sedimantasyon değerleri paçala göre değişkenlik gösterebilir.</p>
              







                  </div>

                </>
                :
                <div className='w-full col-span-12 lg:col-span-10 '>

                  <TypeAnimation
                    sequence={[

                      `${filteredData.name}`,
                      1000

                    ]}
                    cursor={false}
                    speed={1}
                    className='text-white text-5xl '


                  />

                  <div className={` w-full gap-10   grid grid-cols-2 lg:grid-cols-5 mt-5`}>

                    {
                      filteredData.products.map((item, i) => {
                        return (
                          <Link key={i} to={`${path1}/${item.url}`}><motion.div custom={i} animate="visible" initial="hidden" variants={variants} className='cursor-pointer  h-full '>

                            <h5 className='text-white mb-5 text-center'>{item.title_2}</h5>
                            <motion.img className={` hover:scale-105 transition duration-300 `} src={item.image} />
                          </motion.div>
                          </Link>
                        )
                      })
                    }

                  </div>

                </div>
              }
              <div className='w-full   col-span-12 mt-20 space-y-10'>

                {/* <div className='h-auto w-full  flex justify-center '>
                  <motion.div animate={{ opacity: 1, transition: { duration: 2, delay: 1 } }} initial={{ opacity: 0 }} className='w-full md:w-1/2  h-full text-white '>
                    Lorem ipsum dolor sit ametsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

                  </motion.div>
                </div> */}

                <motion.div whileInView={{ opacity: 1, transition: { duration: 1 } }} initial={{ opacity: 0 }} className=' overflow-hidden  relative '>
                  <Parallax  speed={-100} translateY={[-20, 20]} className='relative' >

                    <img src={filteredDataInner?.image_main} className='w-full object-fill' />
                    {/* <div className=' absolute top-1/2 right-[10%] z-40 flex flex-col text-white text-3xl hidden md:block '>
                    <h6 className='montSerrat'>{filteredDataInner?.parallaxText1}</h6>
                    <h6 className='montSerrat'>{filteredDataInner?.parallaxText2}​</h6>
                    </div> */}


                  </Parallax>







                </motion.div>

                <div  className={` w-full  gap-10 grid   lg:grid-cols-${filteredDataInner?.images_inner?.length} `}>
                  {
                    filteredDataInner?.images_inner?.map((item, index) => {
                      return (
                        <motion.div key={index} custom={index} animate="visible" initial="hidden" variants={variants} >
                          <img src={item} className='w-full object-cover' />
                        </motion.div>
                      )
                    })
                  }
                </div>


             

              </div>
            </div>






          </div>








          : <AllProducts/>}



    </div>
  )
}

export default Urunler