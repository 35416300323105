import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// // import './App.css';
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";


import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import Home from "./pages/Home";
import Home33 from "./pages/Home33";


import Home37 from "./pages/Home37";
import { Route, Routes, useLocation } from "react-router-dom";

import Iletisim from "./pages/Iletisim";
import { Home44 } from "./pages/Home44";
import InsanKaynakları from "./pages/InsanKaynakları";
import { ParallaxProvider } from "react-scroll-parallax";
import Urunler from "./pages/Urunler";
import HomeContainer from "./container/HomeContainer";
import { useState } from "react";

import VizyonMisyon from "./pages/VizyonMisyon";
import KaliteYonetimi from "./pages/KaliteYonetimi";
import Tarihce from "./pages/Tarihce";
import YonetimPolitikalarimiz from "./pages/YonetimPolitikalarimiz";

import BakingSquare from "./pages/BakingSquare";
import IM from "./pages/IM";
import InsanKaynaklariPolitikalarimiz from "./pages/InsanKaynaklariPolitikalarimiz";
import Isilanlari from "./pages/Isilanlari";
import UrunTalepFormu from "./pages/UrunTalepFormu";

import { AnimatePresence } from "framer-motion";

// import Bugday from "./pages/BugdayOld";
import UrunlerContainer from "./container/UrunlerContainer";
import GidaYonetimiSistemi from "./pages/GidaYonetimiSistemi";
import Bugday from "./pages/Bugday";
import AFahrein from "./pages/AFahrein";
import MarkayaOzelReceteliUrunler from "./pages/MarkayaOzelReceteliUrunler";
import ProfesyonellerinYaninda from "./pages/ProfesyonellerinYaninda";
import Guncelleniyor from "./pages/Guncelleniyor";





function App() {
  const location = useLocation();
  const [headerTransparent, setHeaderTransparent] = useState(false)

  return (
    <ReactLenis root >
      <ParallaxProvider >
   
        <div className="App  mx-auto bg-black overflow-x-hidden  relative  block   ">

        <AnimatePresence  mode="wait" >
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomeContainer headerTransparent={headerTransparent} />}>
              <Route path="/" element={<Home />} />


              <Route path="products" element={<UrunlerContainer setHeaderTransparent={setHeaderTransparent} />}>
                <Route path=":id"    >
                  <Route path=":id" />
                </Route>


              </Route>

                {/* SİLİNECEK */}
              <Route  path="urunler" element={<Urunler setHeaderTransparent={setHeaderTransparent} />}>
                <Route path=":id"    >
                  <Route path=":id" />
                </Route>
              </Route>

              <Route path="urun-talep-formu" element={<UrunTalepFormu />} />

              <Route path="kurumsal">
                <Route path="tarihce" element={<Tarihce />} />
                <Route path="vizyon-misyon" element={<VizyonMisyon />} />
                <Route path="kalite-yonetimi" element={<KaliteYonetimi />} />
                <Route path="yonetim-politikalarimiz" element={<YonetimPolitikalarimiz />} />
              </Route>


              <Route path="inovasyon">
                <Route path="innovasyon-merkezi" element={<IM/>} />
                <Route path="baking-square" element={<BakingSquare />} />
                {/* <Route path="450f" element={<AFahrein/>} /> */}
              </Route>

              <Route path="profesyonellerin-yaninda" element={<ProfesyonellerinYaninda/>} />

              <Route path="kariyer">
                <Route path="insankaynaklari-politikalarimiz" element={<InsanKaynaklariPolitikalarimiz />} />
                <Route path="is-ilanlari" element={<Isilanlari />} />
              </Route>


              <Route path="markaya-ozel-receteli-urunler" element={<MarkayaOzelReceteliUrunler />} />

              <Route path="guncelleniyor" element={<Guncelleniyor setHeaderTransparent={setHeaderTransparent} />} />

              <Route path="home33" element={<Home33 setHeaderTransparent={setHeaderTransparent} />} />

              <Route path="home37" element={<Home37 setHeaderTransparent={setHeaderTransparent} />} />


              {/* <Route path="innovasyon-merkezi" element={<InnovasyonMerkezi />} /> */}
              <Route path="bugday" element={<Bugday />} />
              <Route path="home44" element={<Home44 />} />
              <Route path="insankaynaklari" element={<InsanKaynakları setHeaderTransparent={setHeaderTransparent} />} />
              <Route path="gida-guvenligi-sistemi" element={<GidaYonetimiSistemi setHeaderTransparent={setHeaderTransparent} />} />

              
              <Route path="Iletisim" element={<Iletisim setHeaderTransparent={setHeaderTransparent}/>} />


              {/* <Route path="products" element={<Products />} /> */}
            </Route>
          </Routes>
          </AnimatePresence>

        </div>
      </ParallaxProvider>
    </ReactLenis>
  );
}

export default App;