import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'

const Iletisim = ({ setHeaderTransparent }) => {

    useLayoutEffect(() => {
        setHeaderTransparent(true)

        return (() => setHeaderTransparent(false))
    }, [])
    return (
        <div className=''>
            <div className='min-h-screen  lg:h-screen w-full pt-28 relative'>
                <div className='relative h-full flex items-center '>
                    <img
                        src="images/Iletisim/banner.jpg"
                        alt="logo"
                        className="  w-full h-full object-center object-cover  absolute "
                    />
                    <div className=' h-full  z-10 relative flex  flex-col min-h-screen   w-full px-8 md:px-16 lg:px-36 py-14 md:py-28 '>
                        <div className='w-full lg:w-1/2 space-y-10'>
                            <h5 className='text-4xl font-bold '>İletişim</h5>
                            <div className='space-y-10'>


                                <div className='flex space-x-5 flex-col '>
                                    <div className='flex  space-x-5'>
                                        <div className='h-10 w-10   flex justify-center '>
                                            <img
                                                src="images/footer-svg/location.svg"
                                                className=" social1"
                                                alt="facebook"
                                            />
                                        </div>
                                        <div className='space-y-5'>
                                            <div>
                                                <a target='_blank' href='https://maps.app.goo.gl/rD3SHC2oWBss2GKLA'><h6 className=' font-bold'>Fabrika ve Merkez Ofis</h6>
                                                    <h6 className='text-gray-950  '>Bozburun Mah. 7001. Sok. No:3 Merkezefendi / Denizli</h6>
                                                </a>
                                            </div>
                                            <div>
                                                <a target='_blank' href='https://maps.app.goo.gl/rD3SHC2oWBss2GKLA'>
                                                    <h6 className='  font-bold'>Silo ve Depolama Tesisi</h6>
                                                    <h6 className='text-gray-950 '>Bozburun Mah. 7055. Sok. No:4 Merkezefendi / Denizli</h6>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div className='flex space-x-5 flex-col'>
                                    <div className='flex  space-x-5'>
                                        <div className='h-10 w-10  icon1 flex justify-center items-end'>
                                            <img
                                                src="images/footer-svg/phone.svg"
                                                className="h-8 social1"
                                                alt="facebook"
                                            />
                                        </div>
                                        <div className='flex space-x-0  flex-col '>

                                            <a href='tel:+902583718300'><h6 className='icon1Text2 text-gray-950 '>{`(+90) 258 371 83 00`}</h6></a>
                                          
                                            <a href='tel:+902583718302'><h6 className='icon1Text3 text-gray-950 '>{`(+90) 258 371 83 02`}</h6></a>
                                        </div>
                                    </div>

                                </div>

                                <div className='flex space-x-5 flex-col'>
                                    <div className='flex  space-x-5 '>
                                        <div className='h-9 w-10  icon1 flex justify-center items-end'>
                                            <img
                                                src="images/footer-svg/mail.svg"
                                                className="h-8 social1"
                                                alt="facebook"
                                            />
                                        </div>
                                        <div className='flex space-x-0 flex-col '>

                                            <a href='mailto:info@inceoglun.com.tr'><h6 className='text-gray-950 icon2Text2'>info@inceoglun.com.tr</h6></a>
                         
                                            <a href='mailto:sales@inceoglun.com.tr'><h6 className='text-gray-950 icon2Text2'>sales@inceoglun.com.tr</h6></a>
                                        </div>
                                    </div>

                                </div>



                                <div className='flex space-x-5 flex-col'>
                                    <div className='flex  space-x-5'>
                                        <div className='h-10 w-10  icon1 flex justify-center items-end'>
                                            <img
                                                src="images/footer-svg/clock.svg"
                                                className="h-8 social1"
                                                alt="facebook"
                                            />
                                        </div>
                                        <div className='flex items-center'>

                                            <h6 className='text-gray-950  '>Pazartesi - Cumartesi 08:30 - 17:30</h6>
                                        </div>
                                    </div>
                                </div>




                            </div>

                        </div>
                    </div>
                </div>



            </div>

        </div>
    )
}

export default Iletisim