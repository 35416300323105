import React from 'react'
import { Link } from 'react-router-dom'

const Section5 = () => {
  return (
    <section className="w-full   md:h-[550px]  flex bg-[#ECE2D1] flex-col md:flex-row arge-container">
    <div className="w-full md:w-1/2 h-72 md:h-full relative flex float-end overflow-hidden arge-img">
      <img
        src="images/general/img-9.png"
        className="  md:h-full  md:right-0  transform  object-cover object-right not-sr-only  "
        // className="min-w-[76.188rem] min-h-[43.375rem] h-[43.375rem] w-[76.188rem] "
        alt="logo"
      />
    </div>

    <div className="w-full md:w-1/2 h-full flex items-center arge-content">
      <div className="space-y-5  md:pr-36 md:pl-28 p-8">
        <div className="flex space-x-10">
          <h6 className="text-3xl font-bold">AR-GE</h6>
          <img
            src="images/general/3.png"
            className="w-[63.07px] h-[52.9px] "
            // className="min-w-[76.188rem] min-h-[43.375rem] h-[43.375rem] w-[76.188rem] "
            alt="logo"
          />
        </div>

        <p className="">
          İnceoğlu Un Fabrikası’nın kuruluşu 1944 yılına dayanır. Hacı Ahmet
          İnceoğlu, Baki Abalıoğlu ve Cafer Sadık Abalıoğlu Kollektif
          Şirketi ortaklığındaki ilk un fabrikası, Eski Çal Yolu üzerinde ve
          25 t/gün kapasiteli olarak kurulmuş ve bu fabrikayla sektörde uzun
          yıllar faaliyetini sürdürmüştür.
        </p>
        <p className="">
          1965 yılından itibaren İnceoğlu Makine Ticaret ve Sanayi A.Ş
          olarak Denizli Un Fabrikaları Kollektif Şirketi’nin ortağı
          olmuştur. 1976 senesinde İzmir Karayolu üzerindeki 150 t/gün
          kapasiteli yeni bir fabrika kurulmuş ve
        </p>
        <Link  to={'kurumsal/vizyon-misyon'}>
        <div className="h-10 w-[173px]  flex justify-center items-center  border border-black mt-5 hover:scale-105 transition-all duration-500">
          <h6 className="text-[10px] font-bold ">DAHA FAZLA İÇİN</h6>
        </div>
        </Link>
      </div>
    </div>
  </section>
  )
}

export default Section5