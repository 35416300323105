import React from 'react'
import { Outlet } from 'react-router-dom'



import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// // import './App.css';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useLayoutEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";


import Footer from "../components/Footer";
import Menu1 from "../components/Menu1";
import { useState } from "react";

import { AnimatePresence } from 'framer-motion'


import { useLocation } from "react-router-dom";
import Header from '../components/Header';

import { motion, useScroll } from "framer-motion"






function HomeContainer({ headerTransparent }) {
  const { scrollYProgress } = useScroll();
  const [menuType, setMenuType] = useState(false);

  const [footer, setFooter] = useState(true)
  let location = useLocation();



  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    setMenuType(false)


    if (location.pathname === '/home37') {
      setFooter(false)
    } else {
      {
        {
          setFooter(true)
        }
      }
    }




  }, [location])


  useGSAP(
    () => {
      // gsap.set(".ball", {xPercent:0, yPercent:0});
      // let xTo = gsap.quickTo(".ball", "x", {}),
      //     yTo = gsap.quickTo(".ball", "y", {});
      // window.addEventListener("mousemove", e => {
      //   xTo(e.clientX);
      //   yTo(e.clientY);
      // });
      const items = [...document.getElementsByClassName('item')]
      const images = [
        '/images/wheat-2/wheat-0.png',
        '/images/wheat-2/wheat-1.png',
        '/images/wheat-2/wheat-2.png',
        '/images/wheat-2/wheat-3.png',
        '/images/wheat-2/wheat-4.png',
        '/images/wheat-2/wheat-5.png',
        '/images/wheat-2/wheat-6.png',
        '/images/wheat-2/wheat-7.png',
        '/images/wheat-2/wheat-8.png',
        '/images/wheat-2/wheat-9.png'
      ];
      items.forEach((item) => {
        const randomIndex = Math.floor(Math.random() * images.length);
        const randomImagePath = images[randomIndex];
        console.log(randomImagePath)
        item.style.background = `url(${randomImagePath}) center center / contain no-repeat`;
      });
      console.log(items)
      // items.forEach((item) => item.style.backgroundColor = `hsl(${Math.random()*360}deg 80% 70%)`)
      // items.forEach((item) => item.style.background = `url(images/wheat-2/wheat-2.png) center center / cover no-repeat`)
      const pos = {
        x: 0,
        y: 0,
      };
      const vel = {
        x: 0,
        y: 0,
      };
      const updatePositionAndCalculateVelocity = (e) => {
        // Mouse X and Y
        const x = e.clientX;
        const y = e.clientY;
        // Animate Pos Object and calculate Vel Object Velocity
        gsap.to(pos, {
          x: x,
          y: y,
          ease: "expo.out",
          duration: 0.3,
          onUpdate: () => {
            vel.x = x - pos.x;
            vel.y = y - pos.y;
          },
        });
      };
      const createAndRunAnimation = (index, rotation = -8) => {
        gsap.to(items[index], {
          ease: "power1.out",
          keyframes: [
            { x: pos.x, y: pos.y, rotation: 0, duration: 0, },
            { x: pos.x + vel.x * 3, y: pos.y + vel.y * 3, rotation: rotation, duration: 1 }
          ]
        });
        var tl = gsap.timeline();
        tl.to(items[index], { scale: 0, opacity: 0, duration: 0, });
        tl.to(items[index], { scale: 1, opacity: 1, duration: 0.5, ease: 'power3.out' });
        tl.to(items[index], { scale: 0, opacity: 0, duration: 0.5, ease: 'power3.in' });
      }
      let index = 0
      let counter = 0
      window.addEventListener("mousemove", (e) => {
        updatePositionAndCalculateVelocity(e);
        if (Math.abs(vel.x) > 10 || Math.abs(vel.y) > 10) {
          if (counter > 10) {
            counter = 0
            createAndRunAnimation(index, vel.x > 0 ? 8 : vel.x < 0 ? -8 : 0)
            if (index + 1 === items.length) {
              index = 0
            } else {
              index += 1
            }
          }
          counter += 1
        }
      });

    })


  return (
    <div className='relative '>

      {/* <motion.div animate={{height:'0vh',transition:{duration:1}}} initial={{height:'100vh'}}  className='fixed  bg-red-600 z-50 w-full opacity-10'></motion.div> */}


      <div class="item">
      </div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>

      {
        <AnimatePresence mode='wait' initial={true} >
          {menuType && <motion.div key="modal" initial={{ opacity: 0 ,translateX:'50vw' }}
          animate={{ opacity: 1,translateX:'0vw' ,transition:{duration:1}}}
          exit={{ opacity: 0 ,translateX:'-50vw' ,transition:{duration:1}}} 
          className={` h-screen overflow-x-hidden  w-full   z-30  bg-black  fixed `}> 
              <Menu1 menuType={menuType} setMenuType={setMenuType} />
              </motion.div>}   
        </AnimatePresence>
      }




      <Header menuType={menuType} setMenuType={setMenuType} headerTransparent={headerTransparent} />
      <Outlet />
      {footer && <Footer />}
      <motion.div style={{ scaleX: scrollYProgress }} className='fixed bottom-px h-px w-full  bg-inceoglunYellow1'></motion.div>
    </div>
  )
}

export default HomeContainer