
export const DATA = [
    {

        image: '/images/products/ekmeklik.png',
        header1: 'Fırın Serisi',
        header2: 'Turuncu Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        teslimSekilleri:[
            '1','2','3','4','5',
        ],

        ibre:
        {
            protein: 12,
            kül: 0.65,
            energy: 250,
            gluten: 28,
            sed: 45,
            stab: null
        },

    },




    {
      
        image: '/images/products/10.png',
        header1: 'Fırın Serisi',
        header2: 'Yeşil Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        teslimSekilleri:[
            '1','2','3','4','5',
        ],

        ibre:
        {
            protein: 11.5,
            kül: 0.65,
            energy: 245,
            gluten: 27,
            sed: 45,
            stab: null
        },

    },
    {
      
        image: '/images/products/3.png',
        header1: 'Fırın Serisi',
        header2: 'Kırmızı Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 11,
            kül: 0.65,
            energy: 240,
            gluten: 27,
            sed: 45,
            stab: null
        },

    },
    {
    
        image: '/images/products/5.png',
        header1: 'Fırın Serisi',
        header2: 'Sarı Ekmeklik',
        type: 'Fırın Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 10.5,
            kül: 0.65,
            energy: 230,
            gluten: 26,
            sed: 40,
            stab: null
        },

    },
    {
     
        image: '/images/products/6.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Kuru Kek, Pasta',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 10.5,
            kül: 0.60,
            energy: 240,
            gluten: 26,
            sed: 40,
            stab: null
        },

    },
    {
     
        image: '/images/products/11.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Pastalık Böreklik',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 0,
            kül: 0,
            energy: 0,
            gluten: 0,
            sed: 0,
            stab: null
        },

    },
    {
    
        image: '/images/products/11.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Poğaça, Açma, Börek',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 12,
            kül: 0.60,
            energy: 270,
            gluten: 28,
            sed: 50,
            stab: null
        },

    },



    {
  
        image: '/images/general/22.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Unlu Mamüller',
        type: 'Pastane Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 10,
            kül: 0.65,
            energy: 230,
            gluten: 26,
            sed: 40,
            stab: null
        },

    },





    {
 
        image: '/images/products/2.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Pidelik',
        type: 'Usta Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 11,
            kül: 0.60,
            energy: 250,
            gluten: 27,
            sed: 45,
            stab: null
        },

    },




    {
   
        image: '/images/products/2.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Simitlik',
        type: 'Usta Serisi',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 0,
            kül: 0,
            energy: 0,
            gluten: 0,
            sed: 0,
            stab: null
        },

    },


    {
  
        image: '/images/products/1.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Yufkalık',
        type: 'Yöresel Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 10,
            kül: 1.2,
            energy: 220,
            gluten: 28,
            sed: 40,
            stab: null
        },

    },

    {
     
        image: '/images/products/4.png',
        header1: 'Özel Amaçlı Buğday Unu',
        header2: 'Tam Buğday Unu',
        type: 'Yöresel Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 12,
            kül: 1.2,
            energy: 230,
            gluten: 29,
            sed: 45,
            stab: null
        },

    },

    {
     
        image: '/images/products/4.png',
        header1: 'Özel Amaçlı Buğday Unu - Pamukkale',
        header2: 'Baklavalık ve Böreklik',
        type: 'Lüks Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 12,
            kül: 0.60,
            energy: 280,
            gluten: 28,
            sed: 50,
            stab: 10
        },

    },



    {
    
        image: '/images/products/4.png',
        header1: 'Özel Amaçlı Buğday Unu - Pamukkale',
        header2: 'Makine Yufkalık',
        type: 'Lüks Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 0,
            kül: 0,
            energy: 0,
            gluten: 0,
            sed: 0,
            stab: 0
        },

    },


    {
      
        image: '/images/products/4.png',
        header1: 'Özel Amaçlı Buğday Unu - Pamukkale',
        header2: 'Sarı Sert Bugday Unu',
        type: 'Lüks Seri',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea caliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud',


        protein: '10,5(min)',
        kül: '0,7-0,8',
        moisture: '14,5',
        pdf: '#',

        ibre:
        {
            protein: 0,
            kül: 0,
            energy: 0,
            gluten: 0,
            sed: 0,
            stab: 0
        },

    },



]

